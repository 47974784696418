.sprightwrapper{display:inline-block;width:40%}
.inner_sprightwrap{display:inline-block;width:40%;}
.sellingprice_wrapper{border-radius:6px;border:solid 1px #eee;background-color:#fff}
@media only screen and (max-width: 767px)
{
.spleftwrapper,.sprightwrapper,.inner_spleftwrap{width:100%;padding:0;display:block;}
.inner_spleftwrap{height:auto;}
.slrimgwpr .slrimg{width:100%;margin-bottom:0px;}
.sellerw_package_spec{width:85%;margin:0 auto;display:block;object-fit:contain;object-position:center;}
.inner_sprightwrap{display:block;width:100%;}
.sellingprice_wrapper{padding:15px;}

}

@media only screen and (min-width : 768px) {
	
    .spleftwrapper, .sprightwrapper{display: block;width:100%}
   
}
