/*onboarding css*/

@charset "utf-8";
.ob_divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #eee;
}
.ml-3 {
  margin-left: 3px;
}
.ftr_btn {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.08);
  z-index: 9;
  bottom: env(safe-area-inset-bottom,70px);
}
.ftr_btn:after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: -70px;
  width: 100%;
  height: 70px;
  /* background: #fff; */
}
.ftr_btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background-color: #0076d7;
  cursor: pointer;
  border: 0;
}
.ftr_btn.disabled button {
  background-color: #777;
}

/* .pos_green_tickmark{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/orderfood/pos_green_tickmark.svg); background-repeat: no-repeat; width: 20px; height: 20px; background-size: 100% auto; display: inline-block;} */

/*steps*/
.step_tab_wpr {
  display: block;
  position: relative;
}
.step_tab_wpr ul {
  display: block;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.step_tab_wpr ul::-webkit-scrollbar {
  display: none;
}
.step_tab_wpr li {
  text-align: center;
  position: relative;
  display: inline-block;
  padding: 0 30px;
  width: 33.33%;
}
.step_tab_wpr li .nextline {
  display: block;
}
.step_circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: solid 4px #fff;
  background-color: #e5e5e5;
  padding: 5px;
  position: relative;
  z-index: 2;
  margin-top: 6px;
}
.white_circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
}
.white_circle.blue_circle {
  background-color: #007aff;
}
.step_tab_wpr li:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 8px;
  border-bottom: 1px dashed #788898;
  width: 100%;
  z-index: 1;
}
.step_tab_wpr li:last-child:after {
  display: none;
}
.step_tab_wpr li.active,
.step_tab_wpr li.active_complete {
  color: #111;
}
.step_tab_wpr li.active .white_circle {
  background-color: #007aff;
}
.step_tab_wpr li.active_complete:after {
  border-bottom: 1px dashed #007aff;
}
.step_tab_wpr li.active_complete .step_circle {
  background-color: #007aff;
}
/*steps*/

/*form*/
.seller_ob_form {
  display: block;
}
.seller_ob_form .group {
  margin-bottom: 25px;
}
.seller_ob_form .label-wrap {
  color: #777;
}
.seller_ob_form .inputMaterial {
  border-width: 1px;
  border-radius: 0;
}
.seller_ob_form .inputMaterial:focus ~ .label-wrap,
.seller_ob_form .inputMaterial:valid ~ .label-wrap
{
  color: #777;
}

.seller_ob_form span.inputMaterial ~ .label-wrap {
  top: -3px;
}
.seller_ob_form .inputMaterial.labelTop ~ .label-wrap {
  font-size: calc(12px + 0.15vw);
  top: -5px;
  font-weight: 600;
}

/*form*/

/**/
.include_tax label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.include_tax label input {
  display: none;
}
.include_tax label .type_checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
}
.include_tax label input[type="checkbox"]:checked + .type_checkbox {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/newprot_w/grabcheckicon.svg);
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 0;
  background-position: center;
  flex-shrink: 0;
}
.include_tax label.alignright {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/**/
.dflexVcenter {
  display: flex;
  align-items: center;
}
/*Store Timing, Delevery Timing, Vacation Plan Table */
.downarrowA {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/sell_droprrow_icon.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: 100% auto;
  display: inline-block;
}
.detailtblbx {
  width: 100%;
  min-height: 97px;
  padding: 0;
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #fff;
}
.detailtblbx .headeBx {
  padding: 10px 12px;
  border-bottom: solid 1px #eee;
  background-color: #f8f8f8;
}
.detailtblbx .contBx {
  padding: 12px;
}
.detailtblbx .removecol {
  width: 100px;
  text-align: right;
  font-size: 0;
}
.addphoto_prnt .sob_closeicn {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/newprot_w/bus-close-icb.png);
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  background-size: 100% auto;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 1;
}

/* Popup */
/* Common */
.ob_popup_outer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
}
.ob_popup_bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  width: 100%;
  min-height: 100px;
  max-height: 90%;
  overflow-y: auto;
}

.ob_popup_bottom.seller_calendar_outer{
  max-height: calc(90% + 100px);
}

.ob_popup_bottom .popcontainer {
  width: 100%;
  height: 100%;
}
.popcontainer .popup_hdr_close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
}

.label-wrap.color777 {
  color: #777777;
}

/* Common */

/* editDayTimeUl */
.editDayTimeUl {
  margin-top: 30px;
}
.editDayTimeUl .editDayTimeli {
  border-bottom: 1px solid #f2f2f2;
  padding: 20px;
}
.editDayTimeUl .editDayTimeli .tickbx {
  width: 28px;
  vertical-align: top;
}
.editDayTimeUl .timinpwrp {
  display: flex;
  align-items: center;
  margin-top: 22px;
}
.editDayTimeUl .timinpwrp .inpBx {
  display: inline-flex;
  width: 100%;
  margin-right:30px;
}
.editDayTimeUl .timinpwrp .inpBx .inrbx {
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  height: 100%;
  margin-left: 30px;
  position: relative;
}
.editDayTimeUl .timinpwrp .inpBx .inrbx:first-child {
  margin-left: 0;
  /*margin-right: 30px;*/
}
.editDayTimeUl .timinpwrp .closeBx {
  display: inline-flex;
  flex-basis: 16px;
  justify-content: end;
  position: absolute;
  right: 0;
}
.editDayTimeUl .timinpwrp select.selctA {
  color: #1a1b1d;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border: 0;
  padding-bottom: 10px;
  background: #fff;
}
.time_sob_closeicn {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/sob_close2-icn.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-size: 100% auto;
  display: inline-block;
}
.applytoggle {
  margin-bottom: 0;
}
.aplytoalltx {
  margin-left: 3px;
}
.colord61 {
  color: #d61616;
}
/* switch */
.switch {
  cursor: pointer;
  display: inline-block;
  height: 18px;
  position: relative;
  width: 34px;
}
.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}
.switch .slider {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 34px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.switch .slider:before {
  background-color: #fff;
  border-radius: 50%;
  bottom: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  width: 16px;
}
.switch input:checked + .slider {
  background-color: #0076d7;
  border: 1px solid #0076d7;
}
.switch input:checked + .slider:before {
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  -webkit-transform: translateX(16px);
}
/* switch */
.editDayTimeUl .timinpwrp .inpBx .inrbx .inputMaterial {
  border-bottom: 0;
  padding: 10px;
  font-weight: normal;
}

.editDayTimeUl .timinpwrp ul.selctA {
  display: block;
  border: 1px solid #f2f2f2;
  position: absolute;
  left: 0;
  width: auto;
  top: 43px;
  background-color: #fff;
  z-index: 10;
  max-height: 331px;
  overflow-y: auto;
}
.editDayTimeUl .timinpwrp ul.selctA li {
  display: block;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px;
  font-size: 12px;
  color: #414e5a;
  cursor: pointer;
}

.editDayTimeUl .timinpwrp ul.selctA li.act {
  background-color: #0076d7;
  color: #fff;
}

/*Comman*/
.seller_calendar {
  padding-bottom: 90px;
}
/*Comman*/

.sell_calendar_icon {
  background: url("https://akam.cdn.jdmagicbox.com/images/icontent/seller/sd_calendar_icon.svg")
    no-repeat;
  width: 16px;
  height: 16px;
}
.sell_calendarleft_icon {
  background: url("https://akam.cdn.jdmagicbox.com/images/icontent/seller/cal-left-arrow.svg")
    no-repeat;
  width: 16px;
  height: 16px;
  display: inline-flex;
}
.sell_calendarright_icon {
  background: url("https://akam.cdn.jdmagicbox.com/images/icontent/seller/cal-right-arrow.svg")
    no-repeat;
  width: 16px;
  height: 16px;
  display: inline-flex;
}

.seller_calendar {
  padding-bottom: 90px;
}
/*calendar head*/
.seller_calendar .seller_calendar_modify {
  border-bottom: 0.5px solid #eee;
  height: 67px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}
.seller_calendar .calendar_modifybtn {
  background: transparent;
  border: none;
  cursor: pointer;
}
/*calendar head*/
/*calendar*/
.seller_calendar .calendar_container {
  background-color: #fff;
  padding: 0 20px;
}
.seller_calendar .calendar_container_body {
  padding-bottom: 10px;
  border-bottom: 1px solid #f2f2f2;
}
.seller_calendar .calendar_container_header {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: space-around;
}
.seller_calendar .calendar_container_title {
  color: #222741;
  font-size: 20px;
  font-weight: 700;
}
.seller_calendar .calendar_table {
  width: 100%;
}
.seller_calendar .calendar_table_item {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #111;
  flex-direction: column;
}
.seller_calendar .calendar_table_item.disabled {
  color: #777;
}
.seller_calendar .row_active {
  background-color: #e9f5fe;
}
.seller_calendar .calendar_table_item:hover,
.seller_calendar .active .calendar_table_item {
  background: #0076d7;
  transition: 0.2s all ease-in;
  color: #ffffff;
}

.seller_calendar .lightactive .calendar_table_item {
  background: #e9f5fe;
}

.seller_calendar .row_active .price {
  display: none;
}
.seller_calendar .calendar_table_row {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
.seller_calendar .calendar_table_header {
  border-bottom: 0.5px solid #eeeeee;
}
.seller_calendar .calendar_table_col {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 7);
  height: 44px;
  text-align: center;
  text-transform: uppercase;
}
.seller_calendar .row_overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 0 20%;
}
.seller_calendar .row_overlay_text {
  flex: none;
  padding: 0 10px;
}
.seller_calendar .row_overlay_left {
  flex: 1;
  position: relative;
}
.seller_calendar .row_overlay_right {
  flex: 1;
  position: relative;
}
.seller_calendar .row_overlay_left:before {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #0076d7;
  display: inline-block;
  border-radius: 100%;
  left: 0;
  top: -1px;
  content: "";
}
.seller_calendar .row_overlay_right:after {
  position: absolute;
  content: "";
  width: 100%;
  border-top: 1px dashed #0076d7;
  left: 0;
  top: 1px;
}
.seller_calendar .row_overlay_right:before {
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: #0076d7;
  display: inline-block;
  border-radius: 100%;
  top: -1px;
  content: "";
  right: 0;
}
.seller_calendar .row_overlay_left:after {
  position: absolute;
  content: "";
  width: 100%;
  border-top: 1px dashed #0076d7;
  right: 0;
  top: 1px;
}

/*calendar*/

/*calendar Content*/
.seller_calendar .calendar_content {
  margin-top: 20px;
  width: 100%;
  padding: 0 20px;
}
.seller_calendar .calendar_inputbox {
  position: relative;
  width: 100%;
}
.seller_calendar .calendar_icon {
  position: absolute;
  left: 0;
  top: 4px;
}
.seller_calendar .calendar_input {
  border: none;
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  padding: 5px 16px 12px;
  display: inline-flex;
  align-items: center;
  background: transparent;
}
.seller_calendar .calendar_inputbtn {
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer;
  background: transparent;
  border: none;
}
/*calendar Content*/

/*calendar button*/
.seller_calendar .seller_btnbox {
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
  z-index: 1;
}
.seller_calendar .seller_button {
  height: 40px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  background-color: #0076d7;
  width: 100%;
  border: none;
}
.seller_calendar .seller_button.disabled {
  background-color: #777;
  cursor: not-allowed;
}
/*calendar button*/

.dividerB {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
  height: 1px;
}
/* form calendar */
.inputMaterial:focus ~ .label-wrap,
.inputMaterial:valid ~ .label-wrap {
  color: #777;
}

/*react calendar*/
.seller_calendar .react-calendar {
  width: 100%;
  border: 0;
}
.seller_calendar .react-calendar button {
  font-size: 14px;
  color: #111;
  font-weight: bold;
}
.seller_calendar .react-calendar__tile--now {
  background: #e9f5fe;
  border-radius: 3px;
}
.seller_calendar .react-calendar__tile--active {
  background: #e9f5fe;
}
.react-calendar__month-view__weekdays__weekday {
  font-size: 13px;
  color: #111;
  font-weight: bold;
}
.seller_calendar .react-calendar__tile--rangeEnd.active {
  background: #0076d7;
  border-radius: 0 3px 3px 0;
  color: #fff;
}
.seller_calendar .react-calendar__tile--rangeStart.active {
  background: #0076d7;
  color: #fff;
  border-radius: 3px 0 0 3px;
}
.seller_calendar .react-calendar__tile:disabled {
  background-color: transparent;
  color: #777;
  font-size: 14px;
}
.seller_calendar .react-calendar__tile--rangeBothEnds.active {
  border-radius: 3px;
}
.seller_calendar .react-calendar__tile--active:enabled:hover,
.seller_calendar .react-calendar__tile--active:enabled:focus {
  color: #fff;
}
.seller_calendar .react-calendar__tile--now:enabled:hover,
.seller_calendar .react-calendar__tile--now:enabled:focus {
  background: #e9f5fe;
}

/*react calendar*/

@media screen and (min-width: 1024px) {
  .seller_calendar .seller_btnbox {
    width: 1022px;
    margin: 0 auto;
    left: unset;
  }
  .ob_popup_outer {
    width: 1022px;
    margin: 0 auto;
    left: unset;
  }
}

/*calendar*/

/*Icons*/
.seller_headback_icon {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/sell_backarrow_icon.svg);
  cursor: pointer;
}

/*Icons*/

/*header_top*/
.seller_header {
  display: block;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 9;
}
/*header_top*/

/*header home*/
.header_home {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 48px;
  padding: 0 20px;
}
.header_home .header_home_left {
  width: 35px;
  flex: none;
  height: 48px;
  display: inherit;
}
.header_home .header_home_center {
  width: calc(100% - 70px);
  flex: none;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
}
.header_home .header_home_right {
  width: 35px;
  height: 48px;
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
/*.header_home .home_center {flex:none;display: inline-flex;align-items: center;}*/
/*.header_home .header_home_text{max-width:85%;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;text-transform:capitalize;padding:0;font-weight:600}*/
.header_home .seller_headback_icon {
  width: 26px;
  height: 48px;
  background-size: 30px;
  background-position: left;
}

/*Inner Header*/
.header_single {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 48px;
  padding: 0 20px;
}
.header_single .header_single_left {
  width: 35px;
  flex: none;
  height: 48px;
  display: inherit;
}
.header_single .header_single_center {
  width: calc(100% - 70px);
  flex: none;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
}
.header_single .header_single_right {
  width: 35px;
  height: 48px;
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.header_single .header_single_text {
  max-width: 85%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
  padding: 0;
  font-weight: 600;
}
.header_single .seller_headback_icon {
  width: 26px;
  height: 48px;
  background-size: 26px;
  background-position: left;
}
/*Inner Header*/

.devider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
}

@charset "utf-8";

.ftr_btn {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.08);
}
.ftr_btn button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background-color: #0076d7;
  cursor: pointer;
  border: 0;
}
.ftr_btn.disabled button {
  background-color: #777;
}

/*multi login*/
.multilogin_wpr {
  display: block;
}
.business_list_wpr {
  display: block;
}
.business_list_wpr li {
  display: block;
  cursor: pointer;
  margin-bottom: 20px;
}
.business_tabs {
  display: block;
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #fff;
  padding: 12px;
  position: relative;
  padding-right: 30px;
}
.business_tabs_img {
  display: inline-block;
  width: 46px;
  height: 46px;
  border-radius: 4px;
  border: solid 1px #707070;
  background-color: #fff;
}
.business_tabs_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.right_arrow {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/sd_advr_leftarrow.svg);
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 100%;
}
.business_tabs .right_arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/*multi login*/

/*steps*/
.step_tab_wpr {
  display: block;
  position: relative;
}
.step_tab_wpr ul {
  display: block;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.step_tab_wpr ul::-webkit-scrollbar {
  display: none;
}
.step_tab_wpr li {
  text-align: center;
  position: relative;
  display: inline-block;
  padding: 0 30px;
  width: 33.33%;
  cursor: pointer;
}
.step_tab_wpr li .nextline {
  display: block;
}
.step_circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  outline: solid 4px #fff;
  background-color: #e5e5e5;
  padding: 5px;
  position: relative;
  z-index: 2;
  margin-top: 6px;
}
.white_circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fff;
}
.white_circle.blue_circle {
  background-color: #007aff;
}
.step_tab_wpr li:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 8px;
  border-bottom: 1px dashed #788898;
  width: 100%;
  z-index: 1;
}
.step_tab_wpr li:last-child:after {
  display: none;
}
.step_tab_wpr li.active,
.step_tab_wpr li.active_complete {
  color: #111;
}
.step_tab_wpr li.active .white_circle {
  background-color: #007aff;
}
.step_tab_wpr li.active_complete:after {
  border-color: #007aff;
}
.step_tab_wpr li.active_complete .step_circle {
  background-color: #007aff;
}
/*steps*/

/*form*/
.seller_ob_form {
  display: block;
}
.seller_ob_form .group {
  margin-bottom: 25px;
}
.seller_ob_form .label-wrap {
  color: #777;
}
.seller_ob_form .inputMaterial {
  border-width: 1px;
}
.seller_ob_form .inputMaterial:focus ~ .label-wrap,
.seller_ob_form .inputMaterial:valid ~ .label-wrap {
  color: #777;
}
.inputMaterial.graytext {
  color: #777;
  background-color: transparent;
  opacity: 1;
}
/*form*/

/*SO - Bussiness detail*/
.addphoto_prnt {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 6px;
  background-color: #eee;
  width: 100%;
  height: 180px;
  flex-direction: column;
  border: dashed 1px #777;
}
.camera_icon {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icons/genio/Gcameraicon.svg);
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 100%;
}
.addphoto_prnt input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
}
.addphoto_prnt img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.group.mb-20 {
  margin-bottom: 20px;
}
.include_tax label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.include_tax label input {
  display: none;
}
.include_tax label .type_checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #e5e5e5;
}
.include_tax label input[type="checkbox"]:checked + .type_checkbox {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/newprot_w/grabcheckicon.svg);
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 0;
  background-position: center;
}
.sob_closeicn {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/sob_closeicn.svg);
  width: 38px;
  height: 38px;
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
}
.addphoto_prnt .sob_closeicn {
  position: absolute;
  right: 0;
  top: 2px;
}
.sd_info_icon {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/sd_info_icon.svg);
  width: 14px;
  height: 14px;
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
}
.seller_ob_form .group .sd_info_icon {
  position: absolute;
  right: 2px;
  top: 18px;
  cursor: pointer;
}
.of_downarrow {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icons/genio/gno_downarrow.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-size: 100% auto;
  display: inline-block;
}
.group .of_downarrow {
  position: absolute;
  right: 0;
  top: 12px;
}
.info_content {
  position: absolute;
  width: 300px;
  right: 0;
  top: 30px;
  background-color: #fef7ce;
  padding: 6px 12px;
  z-index: 2;
}
.info_content:before {
  content: "";
  position: absolute;
  right: 5px;
  top: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fef7ce;
}
.common_list ul {
  display: block;
  border: 1px solid #f2f2f2;
  position: absolute;
  left: 0;
  width: 100%;
  top: 46px;
  background-color: #fff;
  z-index: 2;
  max-height: 260px;
  overflow-y: auto;
}
.common_list li {
  display: block;
  border-bottom: 1px solid #f2f2f2;
  padding: 15px;  
  padding-right: 45px;
  position: relative;
}

.common_list li .gstverified{position: absolute; right: 4px; top: 17px;}

/*SO - Bussiness detail*/

/*SO - Store Location*/
.lctn_map {
  display: block;
  width: 100%;
  height: 180px;
  position: relative;
}
.lctn_map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.coordinate_text {
  display: block;
  width: 100%;
  position: relative;
}
.coordinate_text span {
  padding: 0 5px;
  background-color: #fff;
}
.coordinate_text:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #eee;
  z-index: -1;
}
.odrfdswtch {
  position: relative;
  display: inline-block;
  width: 122px;
  height: 34px;
}
.odrfdswtch input {
  display: none;
}
.odrfdswtch {
  position: relative;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #efefef;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-size: 13px;
  color: #1a1b1d;
  font-weight: 600;
}
.odrfdswtch:before {
  position: absolute;
  content: "Yes";
  height: 26px;
  width: 55px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  color: #1274c0;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
}
input:checked + .odrfdswtch {
  background-color: #efefef;
}
input:focus + .odrfdswtch {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .odrfdswtch:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
  content: "No";
}
.lctn_map .leaflet-container {
  border-radius: 10px;
}
.lctn_map .leaflet-pane .leaflet-marker-icon {
  width: 25px !important;
}

/*SO - Store Location*/

/*SO - Contact details*/
.resendotp {
  position: absolute;
  right: 0;
  bottom: 13px;
  cursor: pointer;
}
.otp_sent {
  position: absolute;
  left: 0;
  top: 48px;
  right: 0;
  width: 100%;
  padding: 8px 20px;
  background-color: #15ce8e;
  text-align: center;
}
.pos_green_tickmark {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/orderfood/pos_green_tickmark.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-size: 100%;
  display: inline-block;
}
.orangemark {
   display: inline-flex;
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 50%;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 12px;
    cursor: pointer;
}
.group .pos_green_tickmark {
  position: absolute;
  right: 0;
  bottom: 12px;
}
.errorcls {
  color: #ec4f4f;
}
button.otpbtn {
  position: absolute;
  right: 0;
  top: 8px;
  padding-right: 0;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #0076d7;
}

/*SO - Contact details*/

/*SO - Tax details*/
.img_wpr {
  position: relative;
  border-radius: 6px;
  border: solid 1px #eee;
  height: 180px;
  background-color: #f2f2f2;
}
.sob_round_closeicn {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/orderfood/sob_round_closeicn.svg);
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  background-size: 100% auto;
  display: inline-block;
}
.img_wpr .sob_round_closeicn {
  position: absolute;
  right: 0;
  top: 0;
}
.seller_ob_form .group label.label-wrap .sd_info_icon {
  position: initial;
  bottom: initial;
  right: initial;
  vertical-align: middle;
}
.apply_gstin_wpr {
  display: block;
}
.apply_gstin_wpr li {
  display: block;
}
.apply_gstin_wpr li label {
  display: block;
  position: relative;
  padding-left: 35px;
}
.apply_gstin_wpr li label input {
  display: none;
}
.apply_gstin_wpr li label .checkbox_circle {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #f2f2f2;
}
.apply_gstin_wpr li label input[type="checkbox"]:checked + .checkbox_circle {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/newprot_w/grabcheckicon.svg);
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 0;
  background-position: center;
}
.apply_gstin_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: solid 1px #0076d7;
  height: 40px;
  background-color: #fff;
  cursor: pointer;
}

/*SO - Tax details*/

/*Search for IFSC*/
.bank_list_autosuggest {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
}
.bank_list_autosuggest li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  cursor: pointer;
}
.bank_list_img {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid #707070;
  border-radius: 50%;
}
.bank_list_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 5px;
}

.branch_list_autosuggest {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
}
.branch_list_autosuggest li {
  display: block;
  padding: 12px 0;
  cursor: pointer;
}

/*Search for IFSC*/

/*bank detail*/
.group.mb-10 {
  margin-bottom: 10px;
}

/*bank detail*/

/*otp_popup*/
.otp_poup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 11;
}
.otp_outer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
}
.inpt_sctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inpt_sctn input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #707070;
  padding: 12px 0;
  text-align: center;
  border-radius: 0;
}
.inpt_sctn span {
  display: inline-flex;
  width: calc((100% / 6) - 20px);
  justify-content: center;
  align-items: center;
}

/*otp_popup*/

/*login popup*/
.loginpopup_wpr {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9;
}
.loginpopup_outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}
.seller_ob_btn {
  width: 100%;
  border-radius: 6px;
  background-color: #0076d7;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
}
.seller_ob_cls_icon {
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/sob_close2-icn.svg);
  width: 20px;
  height: 20px;
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
}
.loginpopup_outer .seller_ob_cls_icon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.loginjdlogo {
  position: relative;
  top: 0;
  display: block;
  padding: 0;
  margin: 0 auto;
  width: 120px;
  height: 30px;
  background: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/prot/justdial.svg)
    no-repeat 0;
  background-size: 100% 100%;
}
.newlogin {
  padding: 30px;
  background-color: #fff;
  position: fixed;
  box-sizing: border-box;
  min-height: calc(100vh - 20px);
  top: 0;
  left: 0;
  width: 100%;
}
.logoblotxt {
  width: 100%;
  display: block;
  margin-top: 12px;
  text-align: center;
  font-size: 14px;
  color: #363d43;
}
.writtxtarea {
  float: left;
  width: 100%;
  margin: 30px 0 0;
  position: relative;
  padding: 0;
}
.writtxtarea input {
  border: 0;
  border-bottom: 1px solid #e4eaef;
  width: 100%;
  outline: none;
  resize: none;
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 16px;
  color: #424242;
  text-transform: capitalize;
  background: transparent;
  border-radius: 0;
}
.loginbtn {
  clear: both;
  padding: 35px 0;
  width: 100%;
}
.bluearrow.disbld {
  background: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/prot/arrowfinaldisable.svg)
    no-repeat -3px -3px;
  background-size: 115%;
  box-shadow: 1px 7px 10px hsl(0deg 0% 80% / 70%);
}
.bluearrow {
  position: relative;
  top: 0;
  display: block;
  margin: 0;
  padding: 0;
  width: 48px;
  background: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/prot_072018/loginblueroundbtn.svg)
    no-repeat -3px -3px;
  background-size: 100%;
  background-position: 50%;
  height: 48px;
  border: none;
  text-indent: -9999px;
  float: right;
  box-shadow: 0 4px 8px 0 rgb(18 116 192 / 60%);
  background-color: transparent;
  border-radius: 80%;
  outline: 0;
  cursor: pointer;
}
/*login popup*/

/*Toast Popup design */
.toastMesgcnewWrp {
  padding: 0;
  bottom: -120px;
  -webkit-transition: bottom 0.5s ease-in, opacity 0.3s ease-in;
  transition: bottom 0.5s ease-in, opacity 0.3s ease-in;
  left: 0;
  width: 100%;
  position: fixed;
  z-index: -1;
  opacity: 0;
  z-index: 9;
}
.toastMesgcnewWrp.show {
  bottom: 80px;
  opacity: 1;
  z-index: 15;
  width: 85%;
  right: 0;
  margin: 0 auto;
}
.toastMesgInr {
  background: rgba(0, 0, 0, 0.7);
  color: #fbfcfc;
  font-weight: 400;
  padding: 7px 15px;
  line-height: 18px;
  justify-content: center;
  width: 100%;
  display: flex;
  text-align: left;
  height: auto;
  border-radius: 25px;
  text-align: center;
}
.toastMesgcnewWrp .toastMesgInr {
  opacity: 0;
}
.toastMesgcnewWrp.show .toastMesgInr {
  opacity: 1;
}

.dblock {
  display: block;
}
.inputMaterial.redborder {
  border-color: #ec4f4f;
}

/*loader*/
.loader_wpr {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 60;
}
.onboarding_loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top-color: #ffffff;
  -webkit-animation: 1.2s spin linear infinite;
  animation: 1.2s spin linear infinite;
}

-webkit-@keyframes spin {
  -webkit-from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  -webkit-to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*loader*/

@media screen and (min-width: 1024px) {
  .loginpopup_wpr,
  .newlogin {
    max-width: 1024px;
    margin: 0 auto;
    border: 1px solid #eeeeee;
    border-top: none;
  }
  .otp_outer {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 500px;
    right: initial;
    bottom: initial;
    border-radius: 10px;
    transform: translate(-50%, -50%);
  }
  .newlogin {
    left: inherit;
    padding: 40px 200px 0;
  }
}

/*Help*/
.help_block {
  border-bottom: 1px solid #eee;
}
.help-qst {
  position: relative;
}
.help-qst:after {
  content: "";
  background: url(//akam.cdn.jdmagicbox.com/images/icontent/newwap/prot_072018/hdrdropdown.svg)
    no-repeat;
  display: inline-block;
  width: 10px;
  height: 7px;
  position: absolute;
  right: 0;
  top: 7px;
  transform: rotate(-90deg);
  transition-duration: 0.6s;
}
.help-qst.actv:after {
  transform: inherit;
  transition-duration: 0.6s;
}

.detailtblbx .contBx .selctA li {
  display: block;
  padding-top: 11px;
}

/* Select Category Search Autosuggest */
.selectcat_autosug_wrp {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 12;
  overflow-y: auto;
}
.selectcat_autosug_wrp .searchboxwrp {
  position: sticky;
  top: 47px;
  background: #fff;
}
.selectcat_autosug_wrp .searchbox {
  position: relative;
  border: solid 1px #707070;
  border-radius: 6px;
  background-color: #fff;
  height: 40px;
}
.selectcat_autosug_wrp .searchbox .searchinputbx {
  border: 0;
  box-shadow: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 44px;
  font-size: 14px;
  color: #111;
  font-weight: 500;
}
.selectcat_autosug_wrp .searchIcon {
  position: absolute;
  top: calc(50% - 11px);
  left: 15px;
  background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/so_search_autosug.svg);
  background-repeat: no-repeat;
  width: 22px;
  height: 22px;
  background-size: 100% auto;
  display: inline-block;
}
.selectcat_autosug_wrp .ulwrp {
  width: 100%;
  padding-bottom: 70px;
}
.selectcat_autosug_wrp .ulwrp .liwrp {
  padding: 20px;
  border-bottom: 1px solid #f2f2f2;
}
.selectcat_autosug_wrp .ulwrp .liwrp:first-child {
  border-top: 1px solid #f2f2f2;
}
.mr-2 {
  margin-right: 4px;
}

/* Select Category Search Autosuggest */
.detailtblbx .contBx .selctA li {
  display: block;
  padding-top: 11px;
}

.disabled#resendButton {
  color: #777777;
  pointer-events: none;
}

.detailtblbx .dtbl .dflexVcenter {
  position: relative;
  padding-left: 60px;
  align-items: flex-start;
  justify-content: flex-start;
}
.detailtblbx .dtbl .dflexVcenter .dayspan {
  position: absolute;
  left: 0;
  top: 0;
}
.timespan_wpr .timespan {
  display: block;
  padding-bottom: 5px;
}

.detailtblbx .dtbl ul {
  padding-top: 11px;
}
.detailtblbx .dtbl ul li {
  position: relative;
  padding-left: 60px;
  padding-top: 5px;
}
.detailtblbx .dtbl ul li .dayspan {
  position: absolute;
  left: 0;
  top: 0;
}
.detailtblbx .dtbl ul li:first-child {
  padding-top: 0;
}
.dflexVcenter .downarrowA {
  transition: all 300ms;
}
.downarrowA.act {
  transform: rotate(-180deg);
}

/*loader 2*/
dotloader {
  display: inline-block;
}
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.dotloader span {
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: #fff;
  margin-left: 5px;
  border-radius: 50%;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
.dotloader span:nth-child(2) {
  animation-delay: 0.2s;
}
.dotloader span:nth-child(3) {
  animation-delay: 0.4s;
}
.dotloader span:nth-child(4) {
  animation-delay: 0.6s;
}
.dotloader span:nth-child(5) {
  animation-delay: 0.8s;
}

/*loader 2*/

.help_wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: calc(100vh + 100px);
  background-color: #fff;
  z-index: 12;
  overflow-y: auto;
}

.lctn_map .leaflet-pane{z-index: 4;}
.lctn_map .leaflet-top, .lctn_map .leaflet-bottom{z-index: 5;}

.group.stdcode_wpr .formStdCode {
  position: absolute;
  left: 0;
  top: 15px;
}
.group.stdcode_wpr .inputMaterial {
  padding-left: 50px;
}
.group.stdcode_wpr .label-wrap {
  left: 50px;
}
.seller_ob_form .group.stdcode_wpr .inputMaterial:focus ~ .label-wrap,
.seller_ob_form .group.stdcode_wpr .inputMaterial:valid ~ .label-wrap {
  left: 0;
}

.of_downarrow.uparrow{transform: rotate(180deg);}
.common_list.businessnature_list ul{bottom: 37px; top: initial;}

.alt_mobile_wpr .group{margin-bottom: 15px;}
.alt_mobile_wpr .group:last-child{margin-bottom: 25px;}
.alt_mob_outer{position: absolute; right: 0; bottom: -20px;}
.addmore{margin-right: 10px;} 
.alt_mobile_wpr .group .inputMaterial::placeholder{font-weight: normal; font-size: 14px; color: #777;}

.updatebank_dtl_wpr{top: 0; left: 0; width: 100%; height: 100%; position: fixed; z-index: 10; background: rgba(0, 0, 0, 0.5); overflow-y: auto;}
.updatebank_dtl_outer{position: absolute; left: 0; bottom: 0; border-radius: 0; background-color: #fff; width: 100%; min-height: 100px;
    max-height: 90%; overflow-y: auto;}
.proceed_btn_wpr{display: flex; justify-content: space-between; align-items: center;}
.proceed_btn_wpr button{display: inline-flex; justify-content: center; align-items: center; height: 40px; padding: 0 15px; width: calc(50% - 10px); background-color: #0076d7; border-radius: 25px; cursor: pointer; border: 0; color: #fff;}
.lineHeight22{line-height: 22px;}
.proceed_btn_wpr button.graybtn {background-color: #a4a3a3; color: #1a1b1d;}

.mylocation_icon{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/map-mylocations.png); width: 22px; height: 22px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.lctn_map .mylocation_icon{border: 0; background-color: transparent;position: absolute;right: 20px; bottom: 30px; cursor: pointer;
    z-index: 5; background-color: rgba(255, 255, 255, 0.7); padding: 10px; border-radius: 6px; width: 25px; height: 25px; background-size: 80%;
    background-position: center; box-shadow: 0 0 4px 1px rgb(0 0 0 / 40%);}


.bodyfixed{overflow: hidden; position: fixed; width: 100%;}


/*Login popup & OTP popup*/
.login_wpr{position: fixed; left: 0; top: 0; right: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.7); z-index: 2;}
.login_outer{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 370px; background-color: #fff; border: 1px solid rgba(0,0,0,.2); border-radius: 6px; box-shadow: 0 3px 9px rgb(0 0 0 / 50%); display: table; outline: 0 none; position: relative; }
.login_header{display: block; padding: 25px 15px 15px; border-bottom: 1px solid #e5e5e5; }
.login_header button{position: absolute; z-index: 2; right: 15px; top: 15px; border: 0; background: url(//akam.cdn.jdmagicbox.com/images/icontent/jdrwd_new/popup_close.png) no-repeat center center; border-radius: 100%;
    height: 25px; text-shadow: unset; width: 25px; color: #c8c8cd; opacity: 1; font-size: 21px; font-weight: 700; line-height: 1; cursor: pointer;}
.popup_title{display: flex; text-align: center; justify-content: center; align-items: center; flex-direction: column;}
.pop-logo{background: url(//akam.cdn.jdmagicbox.com/images/icontent/jdrwd_new/jd_logo_popup.png) no-repeat; display: inline-block; width: 99px; height: 24px; margin-bottom: 5px;}
.pop-subtext{font-size: 13px; font-weight: 400; color: #747474; letter-spacing: .13px; width: 100%; text-align: center;}
.popup_body{display: block; padding: 15px 25px;}
.popup_input{display: block; width: 100%; height: 42px; border-radius: 2px; padding: 0 12px; border-color: #e5e5e5; font-size: 14px; font-weight: 400; letter-spacing: .14px; color: #424242; border-style: solid; border-width: 1px;}
.send_otp_btn{font-size: 16px; font-weight: 500; letter-spacing: .14px; height: 42px; border-radius: 2px; background: #337ab7;padding: 0 15px; display: flex; justify-content: center; align-items: center; width: 100%; border: 0; color: #fff; cursor: pointer;}


.otp_text{display: block; font-size: 13.5px; font-weight: 400; color: #747474; letter-spacing: .14px;}
.otp_number{font-size: 16px; font-weight: 500; color: #424242; letter-spacing: .16px; display: inline-block;}
.modal_edit_icon{background: url(//akam.cdn.jdmagicbox.com/images/icontent/jdrwd_new/popup_edit.png) no-repeat;
    display: inline-block; width: 17px; height: 16px; margin-left: 5px; cursor: pointer;}
.otp_input input{margin: 0 1.97%; width: 12%; padding: 0; color: #424242; font-size: 22px; font-weight: 500; border-left: 0; border-bottom: 1px solid #747474; border-radius: 0; padding-bottom: 5px; text-align: center; -webkit-appearance: none; box-shadow: unset; border-right: 0; border-top: 0;}
.otp_input input:first-child{margin-left: 0;}

.resend_otp_wpr{display: flex; justify-content: space-between; align-items: center; flex-direction: row;}
.resend_text{display: inline-flex; font-size: 14px; font-weight: 400; letter-spacing: .14px; color: #747474;}
.resend_text a{color: #337ab7;}

/*Login pop & OTP popup*/

.shimmer{position: relative; -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, #eeeeee 8%,#dddddd 18%,#eeeeee 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px; overflow:hidden;}

@-webkit-keyframes placeHolderShimmer{
0%{
  background-position:-468px 0
}
100%{
  background-position:468px 0
}
}
@keyframes placeHolderShimmer{
0%{
  background-position:-468px 0
}
100%{
  background-position:468px 0
}
}


.dashboard_subcontainer_shimmer{display: block; padding-top: 80px;}
.dashboard_anaflex_shimmer{display: block; margin-bottom: 20px; padding: 0 20px;}
.common_dflex_shimmer{display: flex; justify-content: space-between; align-items: center; width: 100%;}
.common_dflex_left_shimmer{display: inline-block; height: 25px; background-color: #f2f2f2; width: 40%;}
.common_dflex_right_shimmer{display: inline-block; height: 25px; background-color: #f2f2f2; width: 25%;}
.pt-30{padding-top: 30px;}
.pb-30{padding-bottom: 30px;}
.common_div{display: block; width: 100%; height: 25px; background-color: #f2f2f2;}
.dashboard_forbars_shimmer{display: block; width: 100%; height: 200px; background-color: #f2f2f2;}
.dashboard_anaright_shimmer{display: block; margin-top: 12px;}
.dashboard_anlyflex_shimmer{display:flex; flex-wrap: wrap; justify-content: flex-start;}
.dashboard_anlyflexchd_shimmer{border: 1px solid #f2f2f2; background-color: #f2f2f2; flex: 0 0 30%; margin: 0 5px 14px; padding: 10px; display: inline-flex; align-items: flex-start; flex-direction: column;border-radius: 6px; border-top-left-radius: 13px; position: relative; height: 69px;}
.statusbox_statuswpr_shimmer{display: block; width: calc(100% - 40px);  margin: 0 0 15px 20px; border: 1px solid #eee;
  border-radius: 4px;justify-content: space-between;}
.statusbox_statuscard_shimmer{display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 15px; border-right: 0; border-bottom: 1px solid #eee;}
.statusbox_statuscard_shimmer div{display: block; width: 100%; background-color: #f2f2f2; height: 36px;}

.ftr_btn.skip_save_ftr_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;width: 100%;
  overflow: hidden;
}

.ftr_btn.skip_save_ftr_btn .skipbtn {
  width: 100px;
  color: #414e5a;
  border: 1px solid #dde2e2;
  background-color: #fff;
}
.ftr_btn.skip_save_ftr_btn .savenext_btn {
  width: calc(100% - 112px);
}

/*fresh user popup*/
.freshuser_popup_wpr{position: fixed; left: 0; top: 0; right: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.7); z-index: 11;}
.freshuser_popup_outer{position: absolute; width: calc(100% - 40px); left: 20px; top: 50%; transform: translateY(-50%); background-color: #fff; border-radius: 10px; padding: 20px; margin: 0 auto;}
.freshuse_text b{display: block;}

.createnew_btn{display: flex; justify-content: space-between; align-items: baseline; width: 100%;}
.createnew_btn button{display: inline-flex; height: 36px; justify-content: center; align-items: center; padding: 0 10px; background-color: #fff; border-radius: 6px; color: #0076d7; border: 1px solid #0076d7; width: calc(100% - 85px); cursor: pointer;}
.createnew_btn button:last-child{width: 75px; background-color: #0076d7; color: #fff;}

/*fresh user popup*/

.scroll_tabs {scroll-margin-top: 30px;}

.detail_popup_outer.paymentplan_btn .detail_popup_btn{display: flex; justify-content: space-between;}
.detail_popup_outer.paymentplan_btn button.lbtn{width: 40% !important;}

.comisn_header{display: flex; position: relative; justify-content: flex-end; align-items: center; padding-right: 10px;}
.clsbtn{background-image: url(https://akam.cdn.jdmagicbox.com/images/icons/genio/close-black-icn.svg); display: inline-block; background-size: 60%; background-repeat: no-repeat; padding: 20px; background-position: center; cursor: pointer;}
.commission_fee_outer.pt-20{padding-top: 20px;}

/* onboarding wrapper (desktop) */
.onboarding_left, .progressbar_wpr{display: none;}


/* onboarding wrapper (desktop) */


/* deals */
.deliveryboy_list.deallist .deliveryboy_list_left{padding-left: 0}
.termcontent .termsearch_box{position: relative;width:100%;}
.termcontent .termsearch_input{width:100%;border:none;border-bottom:1px solid #f2f2f2;height:38px;padding:0;position: relative;background:transparent;z-index:1;}
.termcontent .termsearch_input:focus{outline:none}
.termcontent .termsearch_input:focus ~ .termsearch_lable,
.termcontent .termsearch_input:valid ~ .termsearch_lable{color:#777;font-size:12px;top:-7.5px;left:0px;z-index: 1;background:#ffffff;}
.termcontent .termsearch_lable{color:#777;left: 0px;-moz-transition:.2s ease all;pointer-events:none;position: absolute;top:10px;transition:.2s ease all;-webkit-transition:.2s ease all;}
.termcontent .term_list{display:inline-flex;width:100%;flex-direction:column}
.termcontent .term_item{cursor:pointer;padding:16px 0;border-bottom:1px solid #eee;position:relative}
.termcontent .term_arrow{width:16px;height:16px;position:absolute;top:16px;right:0;background-image:url(https://cdn.zeplin.io/5e384948712997b0a5b102e3/assets/9fb83a70-efd8-4c1a-b8a0-b038d984b2a4.svg);background-repeat:no-repeat}
.termcontent .term_button{width:100%;height:40px;background:#0076d7;border:none;cursor:pointer;border-radius:8px;display:inline-flex;align-items:center;justify-content:center}
.termcontent .term_btnbox{position:fixed;bottom:0;left:0;z-index:1;background:#fff;padding:10px 20px;box-shadow:0 -1px 10px 0 rgba(0,0,0,0.08); width: 100%;}
.deal_title{display: flex; justify-content: space-between; align-items: center; flex-flow: nowrap; width: 100%; border-bottom: 1px solid #eeeeee;} 
.ob_faqicn{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/ob_faqicn.svg); width: 18px; height: 18px; display: inline-block; background-size: 100%; background-repeat: no-repeat; cursor: pointer;}
.dealdata_outer{display: flex; width: 100%; flex-direction: column;}
.dealdata_outer li{display: flex; flex-direction: column; width: 100%; border-bottom: 1px solid #eee;}
.dealdata_row{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; width: 100%; padding: 15px 0;}
.daylbl_outer{display: inline-flex; align-items: flex-start; flex-flow: row nowrap; width: 100%;}
.daylbl input{display: none;}
.daylbl .daylbl_circle{display: inline-block; width: 20px; height: 20px; border:1px solid #ccc; border-radius: 50%; background-color: #fff;}
.daylbl input[type=checkbox]:checked + .daylbl_circle{background-image: url(http://akam.cdn.jdmagicbox.com/images/icontent/newwap/newprot_w/grabcheckicon.svg); width: 20px; height: 20px; display: inline-block; border: 0; background-position: center; flex: none;}
.colorccc{color: #ccc;}
.dealdata_col{flex: 1;}
.dealdata_col:nth-child(2){text-align: right;}
.dealdata_col .switch .slider{background-color: #eeeeee;}
.dealdata_outer.predefined_optn li{padding: 15px 0}
.time_wpr{display: flex; width: 100%; padding-left: 32px; flex-flow: row nowrap; justify-content: space-between;
    align-items: center;}
.time_wpr .time_col{flex: 1; margin-right: 10px; border-bottom: 1px solid #eee; padding-bottom: 15px;}
.time_wpr .time_col select{font-size: 14px; color: #111; border: 0; width: 70%; background-color: transparent;}
.time_wpr .time_col.closerow{margin-right: 0; border-bottom: 0; width: 20px; flex: none;}
.time_wpr .time_col:nth-child(3){flex: 0 0 70px;}
.brand_crsicon{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/brand_crsicon.svg); width: 18px; height: 18px; display: inline-block; background-size: 100%; background-repeat: no-repeat; cursor: pointer;}

.dealperiod_wpr{display: flex; flex-direction: column; width: 100%;}
.dealperiod_outer{display: flex; width: 100%; flex-wrap: wrap;}
.dealperiod_tab{display: inline-flex; justify-content: center; align-items: center; height: 30px; border-radius: 15px; border: solid 1px #eee; background-color: #fff; padding: 0 16px; margin-right: 10px; font-size: 12px; color: #111;position: relative; cursor: pointer;}
.dealperiod_tab.act{border-color: #0076d7; background-color: #e5f1fb; color: #0076d7; font-weight: 500;}
.dealperiod_tab.act:before{content: ''; background-image: url(http://akam.cdn.jdmagicbox.com/images/icons/genio/gno_checkboxactiveicn.svg); width: 14px; height: 14px; display: inline-block; background-repeat: no-repeat; background-size: 100%; margin-right: 6px;}
.ftr_btn.ftr_offer_skip_btn {z-index: 1;}
/* deals */

.termcontent .term_content{width: 92%;}

@media screen and (min-width: 1024px) {
  .seller_calendar .seller_btnbox {
    width: 1022px;
    margin: 0 auto;
    left: unset;
  }
  .ob_popup_outer{
    width: 1022px;
    margin: 0 auto;
    left: unset;
  }

  .detail_popup_outer{
    width: 1022px;
    margin: 0 auto;
  }

  .freshuser_popup_outer {
    width: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  
}


@media screen and (min-width: 767px) {

  .info_content{width: 400px;}

}

/* AUdit page */
@font-face{font-family:'Open Sans';src:url(https://static.127777.com/public/t_mob/fonts/open-sans-regular.woff2);font-weight:400}
	@font-face{font-family:'Open Sans';src:url(https://static.127777.com/public/t_mob/fonts/open-sans-600.woff2);font-weight:600}
	html{-webkit-text-size-adjust:none}
	body{background-color:#fff;margin:0;padding:0;font-family:'Open Sans',sans-serif;font-size:14px;line-height:1.42857143;scroll-behavior:smooth;-webkit-overflow-scrolling:touch}
	*{box-sizing:border-box;-webkit-box-sizing:border-box;outline:none;-webkit-backface-visibility:hidden}
	ul,ol,li{list-style:none;margin:0;padding:0;background:none}
	input[type="text" i]:disabled,input:disabled,input[type="text" i]{background-color:transparent;opacity:1;/*-webkit-text-fill-color:#424242*/}
	button,input{font-family:'Open Sans',sans-serif;font-size:12px;font-weight:400}
	.wrpr {display: block; width: 100%; } .font11{font-size:11px} .font12{font-size:12px} .font13{font-size:13px} .font14{font-size:14px} .font15{font-size:15px} .font16{font-size:16px}
	.headerBx{width:100%;height:54px;display:table;position:fixed;top:0;left:0;z-index:55;background:#fff;border-bottom:1px solid #f2f2f2}
	.headerBx .middle-title{width:calc(100% - 100px);color:#414e5a;font-weight:600;text-align:center;display:table-cell;vertical-align:middle}
	/* container*/
	.container {display: block; position: relative; cursor: pointer; font-size: 22px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;top: 60px;
	    padding: 15px; }
	.maintitle {  color: #1a1b1d; font-weight: 600; margin-bottom: 23px; display: block; width: 100%; border-bottom: 1px solid #efefef; padding-bottom: 10px; }
	.subtitle {color: #414e5a; font-weight: 600;  margin-bottom: 40px; display: block; width: 100%}
	.mobileNo {color: #788798; font-weight: 600;  margin-bottom: 10px; display: block; width: 100%;word-break: break-all;}
	.mobileNo .numbr { text-decoration: none; color: #788798;}
	.weight600 { font-weight: 600}
	.weight500 { font-weight: 500}
	.plzNoteTxt {color: #ee4e4e; font-weight: 600;  margin-bottom: 80px; display: block; width: 100%; margin-top: 30px;}
	/* footer */
	.btmFixBtn {position: fixed; bottom: 0; left: 0; right: 0; background: #fff; padding: 10px; box-sizing: border-box; border-top: 1px solid #f2f2f2; z-index: 55; display: flex; justify-content: space-around;} 
	.ftrBlueBtn {width: 100%; height: 48px; background: #1274c0; border: 0; color: #fff; padding: 12px 0; z-index: 8; border-radius: 29px; font-weight: 600; }
	.ftrBlueBtn2 {width: 43%; height: 40px; background: #1274c0; border: 0; color: #fff; z-index: 8; border-radius: 29px; font-weight: 400; align-items: center; display: inline-flex; justify-content: center; -webkit-tap-highlight-color: rgba(193,193,193,0.8); cursor: pointer; }
	.ftrGreyBtn2 {width: 43%; height: 40px; background: #414e5a; border: 0; color: #fff; z-index: 8; border-radius: 29px; font-weight: 400; align-items: center; display: inline-flex; justify-content: center; -webkit-tap-highlight-color: rgba(193,193,193,0.8); cursor: pointer; }

.t_and_c_link{position: fixed; left: 0; bottom: 61px; right: 0; width: 100%; background-color: #fff; padding: 8px 20px;}
.t_and_c_link a{color: #007aff;}
.pb100{padding-bottom: 100px;}



	@media screen and (min-width: 375px) {
	.font11{font-size:calc(11px + 0.14vw)}
	.font12{font-size:calc(12px + 0.14vw)}
	.font13{font-size:calc(13px + 0.14vw)}
	.font14{font-size:calc(14px + 0.14vw)}
	.font15{font-size:calc(15px + 0.14vw)}
	.font16{font-size:calc(16px + 0.14vw)}

	}
	@media (min-width:960px) {
	 .wrpr { width: 960px; margin: 0 auto; float: none; display: table; border: 1px solid #e4eaef; min-height: 100vh;} 
	 .headerBx {position: sticky; position: -webkit-sticky; } 
	 .btmFixBtn {width: 960px; left: calc( (100vw - 960px) / 2); border: 1px solid #f2f2f2; position: fixed;  }
	 .container  {top:0;}
   .t_and_c_link{ width: 960px; margin: 0 auto; }    

	}

	.opac {display: block; position: fixed; z-index: 56; left: 0; top: 0; width: 100%; height: 100%; overflow: hidden; background-color: #000; background-color: rgba(0,0,0,0.6); -webkit-animation-name: fadeIn; -webkit-animation-duration: .4s; animation-name: fadeIn; animation-duration: .4s; }
	.downPop { width: 100%; position: fixed; background: #fff; bottom: -60%; opacity: 1; left: 0; z-index: 1002; overflow-y: hidden; height: 300px; box-sizing: border-box; visibility: hidden; -webkit-animation-name: slideUp; -webkit-animation-duration: 0.5s; animation-name: slideUp; animation-duration: 0.5s; transition-timing-function: ease-in;    min-height: 80px; height: auto; border-radius: 20px 20px 0 0;  overflow: hidden; }
	.downPop.visible {opacity: 1; visibility: visible; bottom: 0; }
	.inrCon { padding: 20px;  display: block;    min-height: 100px;}
	.rowTx {color: #414e5a; font-weight: 600; margin-bottom: 10px; display: block; width: 100%; }
	.downPop .btmFixBtn { border: none;    padding-bottom: 18px; position: relative;}
	.dn {display:none;}
	
  /* Info pages */
  .packmovecontainer{padding: 20px 18px 52px; box-sizing: border-box;}
  .circlediv {height: 80px; width: 80px; display: inline-block;}
  .stockaddedwrap{position:fixed;top:0;left:0;width:100%;height:100%;background-color:#fff;z-index: 1000;}
  .crosswrap{float:right;padding:12px}
  .innerstockwrap{height:156px;width:200px;display:inline-block;position:absolute;top:50%;margin-top:-78px;left:50%;margin-left:-100px}
  .dblock{width:100%;display:block;text-align:center;padding:10px 0 15px}
  .thankstext{font-size:20px;display:block;text-align:center;padding-bottom:4px;color:#414e5a}
  .stocktext{color:#a8b3be;font-weight:400;width:100%;text-align:center;display:block;font-size:13px}
  .checkic {background: url('https://akam.cdn.jdmagicbox.com/images/icontent/newwap/prot_072018/success.svg')no-repeat;display: inline-block;height: 80px;width: 80px;}
  .crossic {background: url('http://akam.cdn.jdmagicbox.com/images/icontent/newwap/edit/close-blue.svg')no-repeat;display: inline-block;height: 80px;width: 80px;}



/* Delivery boy */
.deliveryboy_wpr{display: flex; width: 100%; text-align: center; justify-content: center; align-items:center; flex-flow: row nowrap; flex-direction: column;}
.add_plus_btn{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/add_plus_btn.svg); width: 16px; height: 16px; display: inline-block; background-repeat: no-repeat; background-size: 100% auto;}
.adddeliverybtn{display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; border-radius: 18px; border: solid 1.4px #0076d7; background-color: #fff;height: 36px; padding: 0 24px; color: #0076d7; font-size: 14px; font-weight: 600;}
.ftr_btn.delivery_skipbtn{display: flex; justify-content: space-between; align-items: center; flex-direction: row;}
.ftr_btn.delivery_skipbtn button{width: calc(100% - 122px);}
.ftr_btn.delivery_skipbtn button.disabeld{background-color: #ccc; color: #fff; pointer-events: none;}
.ftr_btn.delivery_skipbtn .skipbtn{width: 112px; border: solid 1px #ccc; background-color: #fff; color: #111; border-radius: 6px;}
.deliveryboy_pic_wpr{display: flex; width: 100%; justify-content: center; align-items: center; flex-flow: row nowrap;}
.deliveryboy_pic_outer{display: inline-block; width: 144px; height: 144px; border-radius: 50%; background-color: #fff; border: solid 3px #eee; padding: 5px; position: relative;}
.deliveryboy_pic_outer img{width: 100%; height: 100%; object-fit: cover; border-radius: 50%;}
.camera_icon_outer{position: absolute; right: 0; bottom: 0; width: 40px; height: 40px; border-radius: 50%; background-color: #fff; padding: 8px; cursor: pointer;}
.camera_icon_outer img{width: 100%; height: 100%; object-fit: contain;}
.error_msg{display: block; width: 100%; color: #eb504b; font-size: 12px;}
.ftr_btn.disabled button{background-color: #ccc;}
.uploadimg_wpr{position: fixed; left: 0; top: 0; right: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.6); z-index: 6;}
.uploadimg_outer{position: fixed; left: 0; bottom: 0; right: 0; width: 100%; background-color: #fff; border-radius: 16px 16px 0 0;}
.uploadimg_header{display: block; width: 100%; padding: 25px 20px; border-bottom: 1px solid #eee;}
.upload_list li{display: flex; width: 100%; justify-content: flex-start; align-items: center; flex-flow: row nowrap; padding: 10px 0; cursor: pointer;}
.capture_photo_icon{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/album_icon.svg); width: 24px; height: 24px; display: inline-block; background-repeat: no-repeat; background-size: 100% auto;}
.upload_list .photocamera_icon{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/photocamera_icon.svg); width: 24px; height: 24px; display: inline-block; background-repeat: no-repeat; background-size: 100% auto;}
.closebtn{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/brand_crsicon.svg); width: 24px; height: 24px; display: inline-block; background-repeat: no-repeat; background-size: 100% auto;}
.uploadimg_header .closebtn{position: absolute; right: 20px; top: 24px; cursor: pointer;}
.otp_wpr{display: flex; justify-content: center; align-items: center; flex-flow: row nowrap; width: 100%;}
.otp_wpr input{display: inline-flex; justify-content: center; align-items: center; border:0; border-bottom: 1px solid #ccc; width: calc((100% / 6)); margin-left: 10px; text-align: center; padding: 10px 0; font-size: 16px; font-weight: 600; color: #1a1b1d;}
.otp_wpr input:first-child{margin-left: 0;}
.otp_wpr input:focus{border-color:#0076d7;}
.resend_otp_wpr{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; width: 100%;}
.alerticon{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/alerticon.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100% auto; vertical-align: middle; position: relative; top: -1px;}
.greencheckicon{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/greencheckicon.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100% auto; vertical-align: middle; position: relative; top: -1px;}
.verification_unsccessful_wpr{display: flex; justify-content: center; align-items: center; flex-flow: row nowrap; width: 100%; flex-direction: column;}
.verification_unsccessful_wpr .closebtn{position: absolute; right: 20px; top: 20px; cursor: pointer;}
.deliverycount{display: block; border-bottom: 1px solid #eee;}
.deliveryboy_list{display: block; position: relative;}
.deliveryboy_list li{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; width: 100%; padding: 12px 0; border-bottom: 1px solid #eee; position: relative;}
.deliveryboy_list_left{position: relative; flex: 0 0 85%; padding-left: 55px;}
.deliveryboy_list_left .deliveryboy_prfl_pic{position: absolute; left: 0; top: 0;}
.deliveryboy_prfl_pic{display: inline-block; width: 40px; height: 40px; border-radius: 50%;}
.deliveryboy_prfl_pic img{width: 100%; height: 100%; object-fit: cover;}
.ob_threedots{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/ob_threedots.svg); width: 20px; height: 20px; display: inline-block; background-repeat: no-repeat; background-size: 100% auto; cursor: pointer;}
.deliveryboy_list li .ob_threedots{position: relative;}
.editbox{position: absolute; right: 0; top: 40px; border-radius: 14px; box-shadow: 0 4px 8px 0 rgba(119, 119, 119, 0.2); border: solid 0.5px #eee; background-color: #fff; padding: 16px 0; z-index: 2; }
.editbox li{display: block; width: 100%; padding: 7px 24px; border-bottom: 0; cursor: pointer;}
.adddeliverybtn.addedbtn{position: fixed; bottom: 76px; left: 50%; transform: translateX(-50%); cursor: pointer;}

.delete_deliveryboy_popup{position: fixed; left: 0; top: 0; right: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.6); z-index: 6;}
.delete_deliveryboy_outer{position: absolute; width: 85%; left: 7.5%; top: 50%; transform: translateY(-50%); background-color: #fff; border-radius: 10px; display: flex; justify-content: center; align-items: center; flex-flow: row nowrap; flex-direction: column;}
.delete_deliveryboy_outer .deliveryboy_pic_outer{width: 76px; height: 76px;}
.delecte_btn{display: flex; justify-content: space-between; align-items: center; flex-flow: row nowrap; width: 100%;}
.delecte_btn button{display: inline-flex; justify-content: center; align-items: center; flex-flow: row nowrap; height: 50px; border-radius: 4px; background-color: #0076d7; cursor: pointer; border:0; width: calc(50% - 5px); color: #fff; font-size: 14px; font-weight: 600;}
.delecte_btn button:first-child{background-color: #fff; border:1px solid #0076d7; color: #0076d7;}

.addDeliveryDetPopUp { position: fixed; left: 0; top: 0; right: 0; width: 100%; height: 100%;  background-color: #fff; z-index: 9; overflow: auto;}
.addDeliveryDetPopUp .header_home{padding: 0;}

.addDeliveryDetPopUp .seller_header_wpr{position: sticky; top: 0; background-color: #fff; z-index: 2;}
/* Delivery boy */

/* order_status (Radio button) */
.order_status{display: block; padding-top: 7px;}
.order_status li{display: block;}
.order_status li label{display: flex; width: 100%; justify-content: flex-start; align-items: center; flex-flow: row nowrap; position: relative; padding: 8px 15px 8px 32px;}
.radiobtn_circle{display: inline-block; width: 20px; height: 20px; border-radius: 50%; border: 1px solid #ccc;}
.order_status li label .radiobtn_wpr{position: absolute; left: 0; top: 10px;}
.order_status li label input{display: none;}
.order_status li label input[type=radio]:checked + .radiobtn_circle{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/ob_radioicon.svg); width: 20px; height: 20px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}

.towcol_flex{display: flex; justify-content: space-between; align-items: flex-start; flex-flow: row nowrap; width: 100%; }
.towcol_flex .group{width: calc(50% - 5px);}

.inputMaterial{height: auto;}
.group .sendOtp_btn{position: absolute; right: 0; top: 15px; border: 0; background-color: transparent;}

/* order_status */

.gen_close_white{background-image: url(https://akam.cdn.jdmagicbox.com/images/icons/genio/gen_close_white.svg); width: 20px; height: 20px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.group .gen_close_white{position: absolute; right: 0; top: 15px;}
.gstverified{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/gstverified.svg); width: 38px; height: 16px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}

/* Delivery Charges */
.deliverycharge_wpr{display: block; position: relative;}
.ob_faqicon{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/ob_faqicon.svg); width: 18px; height: 18px; display: inline-block; background-repeat: no-repeat; background-size: 100%; cursor: pointer;}
.ob_delete{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/seller/ob_delete.svg); width: 20px; height: 20px; display: inline-block; background-repeat: no-repeat; background-size: 100%; cursor: pointer;}
.delcharge_chart{display: block; width: 100%;}
.delcharge_chart_hdr, .delcharge_chart_row{display: flex; justify-content: flex-start; width: 100%; flex-flow: row nowrap; margin-top: 18px;}
.delcharge_chart_hdr_col, .delcharge_chart_row_col{flex: 1 1 100%; margin-left: 24px; position: relative;}
.delcharge_chart_hdr_col:first-child, .delcharge_chart_row_col:first-child{margin-left: 0;}
.delcharge_chart_hdr_col:last-child, .delcharge_chart_row_col:last-child{margin-left: 15px; flex-basis: 20px;}
.delcharge_chart_row_col input{font-size: 16px; font-weight: 500; color:#111; border:0; border-bottom: 1px solid #eee; padding-bottom: 12px; width: 100%;}
.ob_delete.disabled{opacity: 0.35; cursor: not-allowed;}
.addrange_btn{display: flex; justify-content: center; align-items: center; width: 100%; flex-flow: row nowrap;}
.addrange_btn button{display: inline-flex; border-radius: 18px; border: solid 1.4px #0076d7; background-color: #fff; height: 36px; padding: 0 24px; justify-content: center; align-items: center; cursor: pointer;}

.delcharge_popup{position: fixed; left: 0; top: 0; right: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,0.6); z-index: 9;}
.delcharge_popup_outer{position: absolute; left: 0; right: 0; bottom: 0; width: 100%; background-color: #fff; border-radius: 10px 10px 0 0;max-height: calc(100% - 90px); overflow: auto;}
.delcharge_popup_header{position: relative; border-bottom: 1px solid #eee;}
.delcharge_popup_header .seller_ob_cls_icon{cursor: pointer; position: absolute; right: 20px; top: 50%; transform: translateY(-50%);}
.flexbaseline{align-items: baseline;}
.rupeesign{position: absolute; left: 0; top: 0;}
.delcharge_chart_row_col.rupeesign_wpr input{padding-left: 15px;}
.delcharge_chart_row_col input.errorinpt{border-color: red;}
.delcharge_chart_row_col input.disabled{pointer-events: none; color: #777; border-color: #f2f2f2;}

.editDayTimeUl.dealdata_outer{margin-top: 0;}
.editDayTimeUl.dealdata_outer .editDayTimeli{padding: 0; border-color: #eee;}
.editDayTimeUl.dealdata_outer .timinpwrp{margin-top: 0; padding-left: 32px; position:relative;}
.addtime{display: flex; width: 100%; justify-content: flex-start; flex-flow: row nowrap; padding-left: 32px; }

/* Delivery Charges */

.group.textarea_para textarea.inputMaterial{padding-top: 0;}

.adddeliverybtn_outer{position: fixed; bottom: 70px; left: 0; right: 0; width: 100%; z-index: 2;}
.pb-120{padding-bottom: 120px;}


.tnc_wpr{display: flex; width: 100%;}
.tnc_link{text-decoration: underline; display: inline-flex; position: relative; align-items: center; justify-content:flex-start;}  
.tnc_popup_wpr{display:flex; justify-content: center; align-items: center; width: 100%; height: 100%; position: fixed; left: 0; right: 0; top: 0; background-color: rgba(0, 0, 0, 0.6); z-index: 56;}
.tnc_popup_outer{background-color: #fff; border-radius: 15px; width: 85%; display: flex; flex-direction: column; height: 90%;}
.tnc_popup_outer iframe{border: 0; padding: 0; height: 100%; border-radius: 6px;}
.tnc_popup_outer .delecte_btn{border-top: 1px solid #f2f2f2;}
.tnc_popup_outer .delecte_btn button:first-child{background-color: #0076d7;color: #fff;border: 0;}
.tnc_popup_outer .delecte_btn button:last-child{background-color: #fff; border: 1px solid #0076d7; color: #0076d7;}
.tnc_link.tick_icon:before{content:''; background:url('http://akam.cdn.jdmagicbox.com/images/icons/genio/genn_greentickicon.svg') no-repeat; width:16px; height: 16px; display: inline-block; background-size: 100%; margin-right: 8px;}
