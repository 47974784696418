
input.inputarrow_dn[type=number]::-webkit-inner-spin-button, 
input.inputarrow_dn[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.borderbottom{border-bottom:#f2f2f2 solid 1px}
.dividerbottom{border-bottom:#d95858 solid 4px}
.boxwrap{box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);border-radius:4px}
.wdth100{width:100%;}

.clamp3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.dflex{display: flex;justify-content: space-between;align-items: center;}
.flex_list{display: flex;flex-wrap: wrap;justify-content: center;}
.flex_center{display: flex;align-items: center;}
.dtbl{display:table;table-layout:fixed;width:100%}
.dcell{display:table-cell;vertical-align:middle}
.dcell_top{display:table-cell;vertical-align:top}
.dib{display:inline-block}
.divider{background-color:red;height:4px;width:100%;}
.dblock{display:block}
.dn{display:none !important;}
.posrel{position:relative;}
.font0{font-size:0}

.fw400{font-weight:400}
.fw500{font-weight:500}
.fw600{font-weight:600}
.fw700{font-weight:700}

.font9{font-size:9px}
.font10{font-size:10px}
.font11{font-size:11px}
.font12{font-size:12px}
.font13{font-size:13px}
.font14{font-size:14px}
.font15{font-size:15px}
.font16{font-size:16px}
.font17{font-size:17px}
.font18{font-size:18px}
.font19{font-size:19px}
.font20{font-size:20px}
.font22{font-size:22px}
.font23{font-size:23px;}
.font24{font-size:24px}
.font26{font-size:26px}

.color1e2{color: #1e242a;}
.color788{color: #788898;}
.color000{color: #000000;}
.color111{color: #111111;}
.color777{color: #777777;}
.colord61{color: #d61616;}
.color27a{color: #27ad70;}
.colored9{color: #ed9b00;}
.colorf3c{color:#f3c50b;}
.color007{color:#0076d7}
.color339{color: #339d3a}
.colorD61{color: #d61616}
.colorFFF{color: #ffffff}
.color414{color:#414e5a}
.colorfff{color:#fff;}
.color1a1{color:#1a1b1d;}
.colora49{color:#a49b9b;}
.colorc60{color:#c60a0a;}
.p50  {padding: 50px}
.pt50 {padding-top: 50px}
.pl50 {padding-left: 50px}
.pr50 {padding-right: 50px}
.pb50 {padding-bottom: 50px}
.m50  {margin: 50px}
.mt50 {margin-top: 50px}
.ml50 {margin-left: 50px}
.mr50 {margin-right: 50px}
.mb50 {margin-bottom: 50px}
.p45  {padding: 45px}
.pt45 {padding-top: 45px}
.pl45 {padding-left: 45px}
.pr45 {padding-right: 45px}
.pb45 {padding-bottom: 45px}
.m45  {margin: 45px}
.mt45 {margin-top: 45px}
.ml45 {margin-left: 45px}
.mr45 {margin-right: 45px}
.mb45 {margin-bottom: 45px}
.p40  {padding: 40px}
.pt40 {padding-top: 40px}
.pl40 {padding-left: 40px}
.pr40 {padding-right: 40px}
.pb40 {padding-bottom: 40px}
.m40  {margin: 40px}
.mt40 {margin-top: 40px}
.ml40 {margin-left: 40px}
.mr40 {margin-right: 40px}
.mb40 {margin-bottom: 40px}
.p35  {padding: 35px}
.pt35 {padding-top: 35px}
.pl35 {padding-left: 35px}
.pr35 {padding-right: 35px}
.pb35 {padding-bottom: 35px}
.m35  {margin: 35px}
.mt35 {margin-top: 35px}
.ml35 {margin-left: 35px}
.mr35 {margin-right: 35px}
.mb35 {margin-bottom: 35px}
.p30  {padding: 30px}
.pt30 {padding-top: 30px}
.pl30 {padding-left: 30px}
.pr30 {padding-right: 30px}
.pb30 {padding-bottom: 30px}
.m30  {margin: 30px}
.mt30 {margin-top: 30px}
.ml30 {margin-left: 30px}
.mr30 {margin-right: 30px}
.mb30 {margin-bottom: 30px}
.p25  {padding: 25px}
.pt25 {padding-top: 25px}
.pl25 {padding-left: 25px}
.pr25 {padding-right: 25px}
.pb25 {padding-bottom: 25px}
.m25  {margin: 25px}
.mt25 {margin-top: 25px}
.ml25 {margin-left: 25px}
.mr25 {margin-right: 25px}
.mb25 {margin-bottom: 25px}
.p20  {padding: 20px}
.pt20 {padding-top: 20px}
.pl20 {padding-left: 20px}
.pr20 {padding-right: 20px}
.pb20 {padding-bottom: 20px}
.m20  {margin: 20px}
.mt20 {margin-top: 20px}
.ml20 {margin-left: 20px}
.mr20 {margin-right: 20px}
.mb20 {margin-bottom: 20px}
.p15  {padding: 15px}
.pt15 {padding-top: 15px}
.pl15 {padding-left: 15px}
.pr15 {padding-right: 15px}
.pb15 {padding-bottom: 15px}
.m15  {margin: 15px}
.mt15 {margin-top: 15px}
.ml15 {margin-left: 15px}
.mr15 {margin-right: 15px}
.mb15 {margin-bottom: 15px}
.p10  {padding: 10px}
.pt10 {padding-top: 10px}
.pl10 {padding-left: 10px}
.pr10 {padding-right: 10px}
.pb10 {padding-bottom: 10px}
.m10  {margin: 10px}
.mt10 {margin-top: 10px}
.ml10 {margin-left: 10px}
.mr10 {margin-right: 10px}
.mb10 {margin-bottom: 10px}
.p5  {padding: 5px}
.pt5 {padding-top: 5px}
.pl5 {padding-left: 5px}
.pr5 {padding-right: 5px}
.pb5 {padding-bottom: 5px}
.m5  {margin: 5px}
.mt5 {margin-top: 5px}
.ml5 {margin-left: 5px}
.mr5 {margin-right: 5px}
.mb5 {margin-bottom: 5px}
.p0  {padding: 0}
.pt0 {padding-top: 0}
.pl0 {padding-left: 0}
.pr0 {padding-right: 0}
.pb0 {padding-bottom: 0}
.m0  {margin: 0}
.mt0 {margin-top: 0}
.ml0 {margin-left: 0}
.mr0 {margin-right: 0}
.mb0 {margin-bottom: 0}

.tleft{text-align:left}
.tcenter{text-align:center}
.tright{text-align:right}

.vm{vertical-align:middle}

.width100{width:100%}
.width50{width:50%}

/*Animation*/
.animated{animation-duration:0.6s;animation-fill-mode:both;transition-timing-function: ease-in-out;}
.fadeIn{animation-name:fadeIn}
@keyframes fadeIn { from{opacity:0} to{opacity:1} }
/*Animation*/

.font10{font-size:10px;}
.font11{font-size:11px;}
.font12{font-size:12px;}
.font13{font-size:13px;}
.font14{font-size:14px;}
.font15{font-size:15px;}
.font16{font-size:16px;}
.font17{font-size:17px;}
.font18{font-size:18px;}
.font19{font-size:19px;}
.font20{font-size:20px;}
.font22{font-size:22px;}
.font24{font-size:24px;}

/*media Query*/
@media screen and (min-width: 375px){
.font10{font-size: calc(10px + .15vh);}
.font11{font-size: calc(11px + .15vh);}
.font12{font-size: calc(12px + .15vh);}
.font13{font-size: calc(13px + .15vh);}
.font14{font-size: calc(14px + .15vh);}
.font15{font-size: calc(15px + .15vh);}
.font16{font-size: calc(16px + .15vh);}
.font17{font-size: calc(17px + .15vh);}
.font18{font-size: calc(18px + .15vh);}
.font19{font-size: calc(19px + .15vh);}
.font20{font-size: calc(20px + .15vh);}
.font22{font-size: calc(22px + .15vh);}
.font24{font-size: calc(24px + .15vh);}
.font26{font-size: calc(26px + .15vh);}
}
/*Toaster Css*/
.toaster{position:fixed;bottom:85px;left:50%;text-align:center;border-radius:6px;background:rgba(0,0,0,0.7);width:350px;margin:0;transform:translateX(-50%);z-index:13;}
/*Toaster Css End*/
.prclfthdng{padding:0px 0 0 0;}
.inputMaterial:focus ~ .labelwrap, .inputMaterial:valid ~ .labelwrap.addnewtxt{font-size:12px;}
@media only screen and (max-width : 767px) {
    .font16{font-size:15px;}
    .font13{font-size:12px;}
    .font12{font-size:11px;}
    .font14{font-size:13px;}
    .font15{font-size:14px;}
    .font20{font-size:19px;}
    .font18{font-size:17px;}
    .font23{font-size:22px;}
    .font22{font-size:21px;}
    .font26{font-size:25px;}
    .mb-15{margin-bottom:13px;}
    .mb-20{margin-bottom:18px;}
    .mt-20{margin-top:18px;}
    .subcontainer{width:100%;}
    .changebtn{padding:7px 14px;}
    .cardsection{display:flex;flex-wrap:wrap;justify-content:space-between;}
    .cardsection_li{width:48%;display:inline-flex;margin-right:0;}
    .cardsection_li .wsiconbx{width:30px;height:30px;}

    .spleftwrapper,.sprightwrapper,.inner_spleftwrap{width:100%;padding:0;display:block;}
    .inner_spleftwrap{height:auto;}
    .inner_spleftwrap .mb-45{margin-bottom:20px;}
    .slrimgwpr .slrimg{width:100%;margin-bottom:20px;}
    .sellerw_package_spec{width:85%;margin:0 auto;display:block;object-fit:contain;object-position:center;}
    .inner_sprightwrap{display:block;width:100%;}
    .sellingprice_wrapper{padding:15px;}

    .selPay_tabheadwrp{display:flex;align-items:center;justify-content:center;font-size:14px;justify-content:space-between;}
    .selPay_tabheadwrp .tabBx{display:inline-flex;margin:0;}
    .seller_date_range{width:200px;padding:7px 28px;margin:0 auto;display:block;}
    .seller_date_icon{width:15px;height:15px;left:6px;}
    .seller_blackdown_arw{right:8px;width:12px;height:12px;}
    .selPay_contbx .sumry_cardbx,.selPay_contbx .sumry_cardbx:nth-child(2n){width:100%;margin-left:0;}
    .selPay_contbx .sumry_cardbx .datebx{height:35px;line-height:35px;}
    .selPay_contbx .seller_date_range{margin:0;}

    .mobtable{overflow-x:auto;}
    .mobtable table{display:block;overflow-x:auto;white-space:nowrap;}
    .seller_statement_tbl thead th{padding:10px 20px;}
    .seller_statement_tbl tbody td{padding:12px 10px;    display: inline-table;width: 100px;}
    .seller_download_state,.seller_paination{width:58%;justify-content:flex-end;display:inline-flex;align-items:center;}
     .seller_download_state{width:40%;height:32px;line-height:32px;padding:0 10px;}
    .seller_paination ul{display:block;}
    .seller_paination li{width:24px;height:24px;line-height:24px;}
    .seller_page_prearrow,.seller_page_nextarrow{width:13px;height:13px;background-size:contain;}
    .seller_download_icon{width:12px;height:12px;background-size:contain;}
    .dflex.alstatmob{display:block;}
    .seller_amount_balance{margin-right:0;width:49%;}
    .alstatmrgnbtm{margin-bottom:10px;}

    .seller_pay_table thead th{width: 100px; display: inline-table;}
    .seller_pay_table .seller_pay_tab td, .seller_pay_content_bottom table td{width: 100px;}
	
	.seller_upload_blocks{flex-wrap: wrap;}
    .seller_upload_left_sec{width:100%;}
    .seller_upload_left_sec .dcell{vertical-align: top;}
    .seller_upload_data{white-space: normal;margin:10px 0 0;}
    .seller_upload_right_sec{width:100%;margin:15px 0 0;}
    .seller_popup_modal{width:88%;}
    .seller_pop2{background: transparent;}
    .seller_right_popup_modal{width:100%;height:100%;position: fixed;top:0;left:0;;}
    .seller_right_popup_modal .seller_popup_body{padding:20px 20px 100px;overflow-y: auto;}
    .seller_footer{position: fixed;}
    .seller_validate_link{top:3px;}
    .forbrand.font14{font-size:14px;}

.prclfthdng{padding:10px 0 10px 0;}
}
/* Input effect css */
.group{margin-bottom:35px;position:relative}
.label-wrap{color:#999;font-size:14px;font-weight:400;left:0;-moz-transition:.2s ease all;pointer-events:none;position:absolute;top:10px;transition:.2s ease all;webkit-transition:.2s ease all}
.bar{display:block;position:relative;width:100%}
.bar:before,.bar:after{background:#1274c0;bottom:0;content:'';height:2px;-moz-transition:.2s ease all;position:absolute;transition:.2s ease all;webkit-transition:.2s ease all;width:0}
.bar:before{left:50%}
.bar:after{right:50%}
.inputMaterial{border:none;border-bottom:1px solid #757575;display:block;font-size:16px;padding:0 0 10px 0;width:100%;height:40px}
.inputMaterial:focus{outline:none}
.inputMaterial:focus ~ .label-wrap,.inputMaterial:valid ~ .label-wrap{color:#777;font-size:13px;top:-20px}
.inputMaterial:focus ~ .bar:before,.inputMaterial:focus ~ .bar:after{width:50%}
.inputMaterial:focus ~ .highlight{animation:inputHighlighter .3s ease;moz-animation:inputHighlighter .3s ease;webkit-animation:inputHighlighter .3s ease}
.dropdown-icon{background-image:url('https://akam.cdn.jdmagicbox.com/images/icontent/newwap/newdoctor/drdropdown.svg');background-repeat:no-repeat;height:20px;width:20px;display:inline-block;position: absolute;right: 0;top: 50%;margin-top: -10px;}
.groupmt .label-wrap{left:30px}
.groupmt .inputMaterial{padding-left:30px}
.mtext{position: absolute;left: 0;top: 50%;font-size: 14px;margin-top: -10px;color:#788798}
.groupmt .inputMaterial:focus ~ .label-wrap, .groupmt .inputMaterial:valid ~ .label-wrap{left:0}
.crsrpntr{cursor:pointer;}
@-webkit-keyframes inputHighlighter {
from{background:#5264AE}
to{background:transparent;width:0}
}
@-moz-keyframes inputHighlighter {
from{background:#5264AE}
to{background:transparent;width:0}
}
@keyframes inputHighlighter {
from{background:#5264AE}
to{background:transparent;width:0}
}
.noldshowpr{display:flex;align-items:center;justify-content:center;flex-direction:column;}
.noldshow{position:relative;}
.noldshow img{margin:0 auto;}
.subcontainer{max-width:1024px;margin:auto;}
.spmainwrapper{display:block;width:100%;font-size: 0;padding-top:30px;}


.loaderbg{position: fixed; left:  0; top: 0; width: 100%; height: 100%; overflow:hidden; background-color: rgba(0,0,0,0.5); z-index: 9; text-align: center; vertical-align: middle; display: flex;
  justify-content: center; align-items: center;}

.dotloader{display: inline-block;}
@keyframes blink {  
    0% {opacity: .2; }   
    20% {opacity: 1; }   
    100% {opacity: .2;}
}

.dotloader span {display: inline-block; width: 7px; height: 7px; background-color: #fff; margin-left: 5px; border-radius: 50%;
    animation-name: blink; animation-duration: 1.4s; animation-iteration-count: infinite; animation-fill-mode: both; }
.dotloader span:nth-child(2) {animation-delay: .2s; }
.dotloader span:nth-child(3) {animation-delay: .4s; }
.dotloader span:nth-child(4) {animation-delay: .6s; }
.dotloader span:nth-child(5) {animation-delay: .8s; }
.labelwrap{color: #777;font-size: 16px;font-weight: 400;left: 0;-moz-transition: .2s ease all;pointer-events: none;position: absolute;top: 0px;transition: .2s ease all;-webkit-transition: .2s ease all;}
.inputMaterial:focus ~ .labelwrap, .inputMaterial:not([value=""]) ~ .labelwrap{color: #777;font-size:12px;top:-20px;}

.bodyblock{position: absolute; overflow: hidden; width: 100%; top: 0; left: 0;}
.bnsrptwpr{padding-top:56px;padding-bottom:70px;max-width:1024px;margin:0 auto;}
.foradprdctwp button{background:#0076d7;color:#fff;font-weight:500;border-radius:6px;border:0;outline:0;padding:10px 15px;cursor:pointer;}
.scrolltop{position:fixed;bottom:85px;right:35px;width:35px; height:35px; background: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/newprot/b2bH_gryscroll.svg) no-repeat; border-radius: 50%; cursor: pointer; background-position: 50%; z-index: 2; margin-bottom: env(safe-area-inset-bottom);background-size:contain;}
.csrpntr{cursor:pointer;}
/*For Animation Css*/
.fadeInUpo{-webkit-animation-name: fadeInUp;animation-name: fadeInUp;}
.animatedo.fasto{-webkit-animation-duration: .8s;animation-duration: .8s;-webkit-animation-fill-mode: both;animation-fill-mode: both;transition-timing-function: ease-in-out;}
@keyframes fadeInUp{ 
    0% { opacity: 0; -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); } 
    100% { opacity: 1; -webkit-transform: translateZ(0); transform: translateZ(0); }
}
/*For Animation Css End*/
@media all and (max-width: 768px){
    .foradprdctwp button{width:85%;} 
    .scrolltop{bottom:85px;right:20px;width:30px; height:30px;}
    .appclass .subcontainer,.appclass.bnsrptwpr{padding:60px 15px 0;}
    .spmainwrapper{padding-top:10px;}

}
@media only screen and (min-width : 768px) {
	.commission_fee_wpr .container{    padding: unset;}
    .spleftwrapper, .spleftwrapper{display: block;width:100%}
    .commission_fee_wpr .subcontainer{    max-width: 690px;margin: unset;}
    .commission_fee_wpr .inner_spleftwrap{height:unset;    width: 100%;}
    .commission_fee_wpr .inner_sprightwrap{width: 100%;}
    .commission_fee_wpr .slrimgwpr .slrimg{    width: 100%;height: 230px; text-align: center; margin-bottom: 30px;}
}


/* shimmer css start */
.sklanimat{-webkit-animation-duration: 1s;animation-duration: 1s;-webkit-animation-fill-mode: forwards;animation-fill-mode: forwards; -webkit-animation-iteration-count: infinite;animation-iteration-count: infinite;-webkit-animation-name: placeHolderShimmer;animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;animation-timing-function: linear;background: #f6f7f8;background: #eeeeee;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%,#dddddd 18%,#eeeeee 33%);
    -webkit-background-size: 800px 104px;
    background-size: 800px 104px;}
@-webkit-keyframes placeHolderShimmer{
0%{
	background-position:-468px 0
}
100%{
	background-position:468px 0
}
}
@keyframes placeHolderShimmer{
0%{
	background-position:-468px 0
}
100%{
	background-position:468px 0
}
}

.shimerflextop{align-items: flex-start;}
.priceHeading, .spaymentHeading{height: 24px;width: 300px;}
.spriceLeft{width:60%;padding-right: 30px;}
.spriceRight{width:40%;}
.spricesearchtop{padding-right: 30px;}
.spricesearch{width:100%;height:40px;}
.spricesellprice{width:48%;height:40px;}
.spriceimgbox{width: 326px;height:230px;}
.spriceRightbox{border-radius: 6px;border: solid 1px #eee;padding:25px 20px;}
.spriceRleft{width: 40%;height: 42px;}
.spriceRRight{width: 40%;height: 42px;}
.spaytabsli{width:120px;height:43px;display: inline-block;margin-right:5px;}
.spaymentbox{border-radius: 6px;border: solid 1px #eee;padding:25px 20px;width: 49%;}
.spaymenthd1{width: 40%;height:26px;}
.spaymentsubtxt{width:100%;height:40px;}
.spaymentprice{width: 40%;height:26px;}
.ssettingHeadingsub{width:250px;height:20px;}
.ssettingHeading{height: 38px;width: 300px;}
.ssettinginnerhead{width:200px;height:20px;}
.ssettinginnerhead2{width:250px;height:16px;}
.ssettingRight{width:150px;height:26px;}
.sbuyerqtab{height:40px;display: inline-block;width: 32%;}
.sbuyerimg{width: 82px;height: 82px;}
.sbuyerproductname{width:200px;height:20px;}
.sbuyerproductname2{width:70px;height:16px;}
.sbuyerproductname3{width:160px;height:20px;}
.sbuyerqans{width: 20%;height:18px;}
.sreviewtabs{width: 80px;height:24px;display: inline-block;margin-right: 20px;}
.sreviewimgae{display: inline-block;width: 40px;height: 40px;border-radius: 50%;}
.sreviewRdatasub{width:140px;height:16px;}
.sreviewbtmdata{width: 120px; height:16px;}
.sreviewbtmdata2{width: 160px; height:16px;}
.sreviewreply{width:90px;height:16px;display: inline-block;}
.sklanimat.rplytxt::before{display: none;}
.commission_fee_wpr .container{top: 0;}
.commission_fee_wpr .inner_sprightwrap input{width:100%}

@media all and (max-width : 768px){
    .priceHeading, .spaymentHeading{width: 250px;height: 18px;}
    .spriceLeft{width: 100%;padding:0;}
    .spricesearchtop, .shimerflextop{padding: 0;flex-direction: column;width:100%;}
    .spricemobflex{flex-direction: column;}
    .spriceimgbox, .spriceRight{width: 100%;}
    .spaytabsli{width:30%;height: 26px;}
    .spaymentboxwrap{flex-direction: column;}
    .spaymentbox{width: 100%;}
    .sreviewwrap{padding:20px}
    .sreviewtabs{margin-right:10px;}
    .sreviewRdatasub{width:100px;}
    .sreviewwrap .rplytxtwrap{width: 100%;margin:0;}
    .commission_fee_wpr .commission_chart_tabs li{padding-left:unset;    font-size: 12px;border-radius:0; border-style: solid; border-color: #0076d7;border-width: 1px 0 1px 1px;    border: 1px solid #0076d7;}
    .commission_fee_wpr   .commission_chart_tabs {padding:0}
    .container{z-index: 1;}
}
/* shimmer css end */
