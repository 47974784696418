@charset "utf-8";
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');*/

/*font*/
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*font*/

* {box-sizing: border-box;outline: medium none; text-decoration:none;}
img {max-width:100%; border: 0;vertical-align: middle;}
a{text-decoration: none;border: 0;outline: none; cursor: pointer;}
a:hover, a:active, a:visited, a:focus {text-decoration: none; outline: 0 none;}
ul, ol, li{ list-style:none; margin:0; padding:0; background:none;}
body{margin:0 auto; padding:0;-webkit-overflow-scrolling: touch; touch-action: manipulation;}
hr{ border: none;}
body, input, textarea, select, button {text-rendering: optimizelegibility;font-family: 'Roboto', sans-serif;-webkit-tap-highlight-color:transparent;}
input[type=number].input-number–noSpinners {-moz-appearance: textfield;}
input[type=number].input-number–noSpinners::-webkit-inner-spin-button,
input[type=number].input-number–noSpinners::-webkit-outer-spin-button{-webkit-appearance: none;margin: 0;}
select::-ms-expand {display: none;}
select {-webkit-appearance: none; -moz-appearance: none; appearance: none; } 
p{margin: 0 0 10px;}
h1, h2, h3, h4, h5, h6{font-size:100%;margin:0;padding:0}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {display: block;}
input::-ms-clear {width : 0;height: 0;}

html{height: 100vh;}

.seller_wrapper{display:inline-block;width:100%;position:relative}
.seller_icon{display: inline-block;background-repeat: no-repeat; vertical-align: middle; background-size: contain; background-position: center; flex: none; transition-duration: .6s;}
.seller_rupicon{font-family: system-ui; font-weight: normal; flex: none; width: auto;}
.rupees{font-family:system-ui;margin-right:2px}

.dflex{display: flex;justify-content: space-between;align-items: center;}
.dtbl{display:table;table-layout:fixed;width:100%}
.dcell{display:table-cell;vertical-align:middle}
.dcell_top{display:table-cell;vertical-align:top}
.dib{display:inline-block}
.divider{background-color:red;height:4px;width:100%;}
.curs_pointer{cursor: pointer;}
.text_uppercase{text-transform:uppercase}
.dn{display:none!important}
.text_left{text-align:left}
.text_right{text-align:right}
.text_center{text-align:center}
.jcc {justify-content: center !important}

.fw300{font-weight:300}
.fw400{font-weight:400}
.fw500{font-weight:500}
.fw600{font-weight:600}
.fw700{font-weight:700}
.fw800{font-weight:800}

.font9{font-size:9px}
.font10{font-size:10px}
.font11{font-size:11px}
.font12{font-size:12px}
.font13{font-size:13px}
.font14{font-size:14px}
.font15{font-size:15px}
.font16{font-size:16px}
.font17{font-size:17px}
.font18{font-size:18px}
.font19{font-size:19px}
.font20{font-size:20px}
.font22{font-size:22px}
.font24{font-size:24px}
.font25{font-size:25px}
.font30{font-size:30px}


.colorfff{color: #ffffff}
.color1a1{color: #1a1b1d}
.color414{color: #414e5a}
.color25d{color: #25d366}
.color788{color: #788798}
.color006{color: #0064e0}
.color3e3{color: #3e3f42}
.colore24{color: #e24e28}
.color232{color: #232425}
.color363{color: #363d43}
.colore4e{color: #e4eaef}
.color19c{color: #19c75e}
.color4c3{color: #4c34c8}
.colora8b{color:#a8b3be}
.colore22{color:#e22e2e}
.color111{color:#111111}
.color007{color:#0076d7}
.color777{color:#777777}
.color707{color:#707070}
.color8A8{color:#8a8a8a}
.colorA4A{color:#a4a3a3}
.color008{color:#008b0a}
.colorFF0{color:#ff0000}
.color4E8{color:#4e8e1c}
.color000{color:#000;}


.p-50  {padding: 50px}
.pt-50 {padding-top: 50px}
.pl-50 {padding-left: 50px}
.pr-50 {padding-right: 50px}
.pb-50 {padding-bottom: 50px}
.m-50  {margin: 50px}
.mt-50 {margin-top: 50px}
.ml-50 {margin-left: 50px}
.mr-50 {margin-right: 50px}
.mb-50 {margin-bottom: 50px}
.p-45  {padding: 45px}
.pt-45 {padding-top: 45px}
.pl-45 {padding-left: 45px}
.pr-45 {padding-right: 45px}
.pb-45 {padding-bottom: 45px}
.m-45  {margin: 45px}
.mt-45 {margin-top: 45px}
.ml-45 {margin-left: 45px}
.mr-45 {margin-right: 45px}
.mb-45 {margin-bottom: 45px}
.p-40  {padding: 40px}
.pt-40 {padding-top: 40px}
.pl-40 {padding-left: 40px}
.pr-40 {padding-right: 40px}
.pb-40 {padding-bottom: 40px}
.m-40  {margin: 40px}
.mt-40 {margin-top: 40px}
.ml-40 {margin-left: 40px}
.mr-40 {margin-right: 40px}
.mb-40 {margin-bottom: 40px}
.p-35  {padding: 35px}
.pt-35 {padding-top: 35px}
.pl-35 {padding-left: 35px}
.pr-35 {padding-right: 35px}
.pb-35 {padding-bottom: 35px}
.m-35  {margin: 35px}
.mt-35 {margin-top: 35px}
.ml-35 {margin-left: 35px}
.mr-35 {margin-right: 35px}
.mb-35 {margin-bottom: 35px}
.p-30  {padding: 30px}
.pt-30 {padding-top: 30px}
.pl-30 {padding-left: 30px}
.pr-30 {padding-right: 30px}
.pb-30 {padding-bottom: 30px}
.m-30  {margin: 30px}
.mt-30 {margin-top: 30px}
.ml-30 {margin-left: 30px}
.mr-30 {margin-right: 30px}
.mb-30 {margin-bottom: 30px}
.p-25  {padding: 25px}
.pt-25 {padding-top: 25px}
.pl-25 {padding-left: 25px}
.pr-25 {padding-right: 25px}
.pb-25 {padding-bottom: 25px}
.m-25  {margin: 25px}
.mt-25 {margin-top: 25px}
.ml-25 {margin-left: 25px}
.mr-25 {margin-right: 25px}
.mb-25 {margin-bottom: 25px}
.p-20  {padding: 20px}
.pt-20 {padding-top: 20px}
.pl-20 {padding-left: 20px}
.pr-20 {padding-right: 20px}
.pb-20 {padding-bottom: 20px}
.m-20  {margin: 20px}
.mt-20 {margin-top: 20px}
.ml-20 {margin-left: 20px}
.mr-20 {margin-right: 20px}
.mb-20 {margin-bottom: 20px}
.p-15  {padding: 15px}
.pt-15 {padding-top: 15px}
.pl-15 {padding-left: 15px}
.pr-15 {padding-right: 15px}
.pb-15 {padding-bottom: 15px}
.m-15  {margin: 15px}
.mt-15 {margin-top: 15px}
.ml-15 {margin-left: 15px}
.mr-15 {margin-right: 15px}
.mb-15 {margin-bottom: 15px}
.p-14  {padding: 14px}
.pt-14 {padding-top: 14px}
.pl-14 {padding-left: 14px}
.pr-14 {padding-right: 14px}
.pb-14 {padding-bottom: 14px}
.m-14  {margin: 14px}
.mt-14 {margin-top: 14px}
.ml-14 {margin-left: 14px}
.mr-14 {margin-right: 14px}
.mb-14 {margin-bottom: 14px}
.p-12  {padding: 12px}
.pt-12 {padding-top: 12px}
.pl-12 {padding-left: 12px}
.pr-12 {padding-right: 12px}
.pb-12 {padding-bottom: 12px}
.m-12  {margin: 12px}
.mt-12 {margin-top: 12px}
.ml-12 {margin-left: 12px}
.mr-12 {margin-right: 12px}
.mb-12 {margin-bottom: 12px}
.p-10  {padding: 10px}
.pt-10 {padding-top: 10px}
.pl-10 {padding-left: 10px}
.pr-10 {padding-right: 10px}
.pb-10 {padding-bottom: 10px}
.m-10  {margin: 10px}
.mt-10 {margin-top: 10px}
.ml-10 {margin-left: 10px}
.mr-10 {margin-right: 10px}
.mb-10 {margin-bottom: 10px}
.p-8  {padding: 8px}
.pt-8 {padding-top: 8px}
.pl-8 {padding-left: 8px}
.pr-8 {padding-right: 8px}
.pb-8 {padding-bottom: 8px}
.m-8  {margin: 8px}
.mt-8 {margin-top: 8px}
.ml-8 {margin-left: 8px}
.mr-8 {margin-right: 8px}
.mb-8 {margin-bottom: 8px}
.p-6  {padding: 6px}
.pt-6 {padding-top: 6px}
.pl-6 {padding-left: 6px}
.pr-6 {padding-right: 6px}
.pb-6 {padding-bottom: 6px}
.m-6  {margin: 6px}
.mt-6 {margin-top: 6px}
.ml-6 {margin-left: 6px}
.mr-6 {margin-right: 6px}
.mb-6 {margin-bottom: 6px}
.p-5  {padding: 5px}
.pt-5 {padding-top: 5px}
.pl-5 {padding-left: 5px}
.pr-5 {padding-right: 5px}
.pb-5 {padding-bottom: 5px}
.m-5  {margin: 5px}
.mt-5 {margin-top: 5px}
.ml-5 {margin-left: 5px}
.mr-5 {margin-right: 5px}
.mb-5 {margin-bottom: 5px}
.p-4  {padding: 4px}
.pt-4 {padding-top: 4px}
.pl-4 {padding-left: 4px}
.pr-4 {padding-right: 4px}
.pb-4 {padding-bottom: 4px}
.m-4  {margin: 4px}
.mt-4 {margin-top: 4px}
.ml-4 {margin-left: 4px}
.mr-4 {margin-right: 4px}
.mb-4 {margin-bottom: 4px}
.p-2  {padding: 2px}
.pt-2 {padding-top: 2px}
.pl-2 {padding-left: 2px}
.pr-2 {padding-right: 2px}
.pb-2 {padding-bottom: 2px}
.m-2  {margin: 2px}
.mt-2 {margin-top: 2px}
.ml-2 {margin-left: 2px}
.mr-2 {margin-right: 2px}
.mb-2 {margin-bottom: 2px}
.p-0  {padding: 0}
.pt-0 {padding-top: 0}
.pl-0 {padding-left: 0}
.pr-0 {padding-right: 0}
.pb-0 {padding-bottom: 0}
.m-0  {margin: 0}
.mt-0 {margin-top: 0}
.ml-0 {margin-left: 0}
.mr-0 {margin-right: 0}
.mb-0 {margin-bottom: 0}
.pb-70{padding-bottom: 70px;}
.pb-100{padding-bottom: 100px;}
.pb-170{padding-bottom: 170px;}





/*Radio and checkbox*/
	@keyframes shrink-bounce {0%{transform:scale(1)} 33%{transform:scale(.85)} 100%{transform:scale(1)} }
	@keyframes checkbox-check {0%{width:0;height:0;border-color:#ffffff;transform:translate3d(0,0,0) rotate(45deg)} 33%{width:1px;height:0;transform:translate3d(0,0,0) rotate(45deg)} 100%{width:4px;height:9px;border-color:#ffffff;transform:translate3d(0,-10px,0) rotate(45deg)} }
	.animtabbox{align-items:center;justify-content:flex-start;display:flex;width:100%}
	.animradio[type='radio']{height:0;width:0;display:none}
	.animradio[type='radio'] + .animlabel{position:relative;display:flex;align-items:flex-start;transition:color 250ms cubic-bezier(.4,.0,.23,1)}
	.animradio[type='radio'] + .animlabel > .animicon{flex:0 0 20px;width:20px;height:20px;background:transparent;border:1px solid #a8b3be;border-radius:20px;cursor:pointer;transition:all 250ms cubic-bezier(.4,.0,.23,1);position:relative}
	.animradio[type='radio'] + .animlabel:hover > .animicon,.animradio[type='radio']:focus + .animlabel > .animicon{background:rgba(255,255,255,.1)}
	.animradio[type='radio']:checked + .animlabel > .animicon{border:10px solid #1274c0;animation:shrink-bounce 200ms cubic-bezier(.4,.0,.23,1)}
	.animradio[type='radio']:checked + .animlabel > .animicon:before{content:"";position:absolute;top:0px;left:-6px;border-right:2px solid transparent;border-bottom:2px solid transparent;transform:rotate(45deg);transform-origin:0 100%;animation:checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards}

	.animradio[type='checkbox']{height:0;width:0;display:none}
	.animradio[type='checkbox'] + .animlabel{position:relative;display:flex;align-items:flex-start;transition:color 250ms cubic-bezier(.4,.0,.23,1)}
	.animradio[type='checkbox'] + .animlabel > .animicon{flex:0 0 20px;width:20px;height:20px;background:transparent;border:1px solid #a8b3be;cursor:pointer;transition:all 250ms cubic-bezier(.4,.0,.23,1);position:relative}
	.animradio[type='checkbox'] + .animlabel:hover > .animicon,.animradio[type='radio']:focus + .animlabel > .animicon{background:rgba(255,255,255,.1)}
	.animradio[type='checkbox']:checked + .animlabel > .animicon{border:10px solid #1274c0;animation:shrink-bounce 200ms cubic-bezier(.4,.0,.23,1)}
	.animradio[type='checkbox']:checked + .animlabel > .animicon:before{content:"";position:absolute;top:0px;left:-6px;border-right:2px solid transparent;border-bottom:2px solid transparent;transform:rotate(45deg);transform-origin:0 100%;animation:checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards}
/*Radio and checkbox*/



/*Modal*/
	
	/*Modal Animation*/
		@keyframes loans_modal_up{from{opacity:0;left:0;bottom:0%;transform:translateY(200%)} to{opacity:1;transform:translate(0%,0%)}}
		.loans_modal_animation{animation-duration:.3s;animation-fill-mode:both}
		.loans_modal_up{animation-name:loans_modal_up;left:0;bottom:0%;animation-fill-mode:forwards}
	/*Modal Animation*/
	
	/*Modal Icons*/
		.modal_close_icon{background-image:url(https://akam.cdn.jdmagicbox.com/images/icontent/loans/modal_close_icon.svg);cursor:pointer;height:30px;width:30px;position:absolute;right:20px;top:25px;}
	/*Modal Icons*/



	/*Modal Commen*/
		.loans_modal{top:0;left:0;width:100%;height:100%;position:fixed;z-index:10;background:rgba(0,0,0,.5);overflow-y:auto}
		.loans_modal_content{display:block;background:#fff;padding:0;border-radius:15px 15px 0 0;position:absolute;width:100%;}
		.loans_modal_close{position: absolute; right: 0; top: -30px; background: transparent; width: 60px; height: 25px; cursor: pointer; display: inline-flex; align-items: center; justify-content: center; border: none; line-height: 1.38; letter-spacing: 1.05px;}
		.loans_modal_header{border-bottom:solid 1px #f2f2f2;}
	/*Modal Commen*/
/*Modal*/


/*Animation*/
.animated{animation-duration:0.6s;animation-fill-mode:both;transition-timing-function: ease-in-out;}
.fadeInUp{animation-name:fadeInUp}
@keyframes fadeInUp { from{opacity:0;transform:translate3d(0,100%,0)} to{opacity:1;transform:none} }
.fadeIn{animation-name:fadeIn}
@keyframes fadeIn { from{opacity:0} to{opacity:1} }
/*Animation*/


/*input css*/
.group{margin-bottom:30px;position:relative}
.label-wrap{color:#788798;font-weight:400;left:0; -moz-transition:.2s ease all;pointer-events:none;position:absolute;top:15px;transition:.2s ease all; -webkit-transition:.2s ease all}
.bar{display:block;position:relative;width:100%}
.bar:before,.bar:after{background:#1274c0;bottom:0;content:'';height:2px; -moz-transition:.2s ease all;position:absolute;transition:.2s ease all; -webkit-transition:.2s ease all;width:0}
.bar:before{left:50%}
.bar:after{right:50%}
.inputMaterial{border:none;border-bottom:2px solid #f2f2f2;display:block;font-size:calc(16px + 0.15vw);padding:15px 0 12px 0;width:100%; color:#111;font-weight: 600; text-overflow: ellipsis; padding-right: 25px; background-color: transparent; cursor: pointer;}
.inputMaterial:focus{outline:none}
.inputMaterial:focus ~ .label-wrap, .inputMaterial:valid ~ .label-wrap{font-size:calc(13px + 0.15vw);top:-5px; font-weight: 600;}
.inputMaterial:focus ~ .bar:before, .inputMaterial:focus ~ .bar:after{width:50%}
.inputMaterial[value]:not([value=""]):disabled ~ .label-wrap{color:#777;font-size:calc(13px + 0.15vw);top:-5px;}


/*input css*/

.otp_outer .popup_hdr_close {position: absolute; right: 0; top: 0; padding: 15px;}


@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}



.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}


@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}


@media screen and (min-width: 375px){

.font10{font-size: calc(10px + .15vh);}
.font11{font-size: calc(11px + .15vh);}
.font12{font-size: calc(12px + .15vh);}
.font13{font-size: calc(13px + .15vh);}
.font14{font-size: calc(14px + .15vh);}
.font15{font-size: calc(15px + .15vh);}
.font16{font-size: calc(16px + .15vh);}
.font17{font-size: calc(17px + .15vh);}
.font18{font-size: calc(18px + .15vh);}
.font19{font-size: calc(19px + .15vh);}
.font20{font-size: calc(20px + .15vh);}
.font22{font-size: calc(22px + .15vh);}
.font24{font-size: calc(24px + .15vh);}


}
@media screen and (min-width: 960px) {
  .choose_plan_wrp .planselbxwrp {justify-content: flex-end;}
  .choose_plan_wrp .planselbx:nth-child(1) {width:178px} 
  .choose_plan_wrp .planselbx:nth-child(2) {width:187px} 
  .choose_plan_wrp .planselbx:nth-child(3) {width:225px} 
}

@media screen and (min-width:1024px){
	.onboarding_outer{height: 100vh; display: table; width: 1024px; margin: 0 auto;}
	.onboarding_outer{max-width:1024px;margin:0 auto;border: 1px solid #eeeeee;border-top: none; }
	.otp_sent, .ftr_btn, .otp_outer{max-width:1024px;margin:0 auto;}
  .selectcat_autosug_wrp{max-width:1024px;margin:0 auto;}
  .onboarding_outer .updatebank_dtl_wpr{width: 1024px; left: 50%; transform: translateX(-50%); max-width:1024px;margin:0 auto;}
}

/* Choose Plan Details */
.choose_plan_wrp {width:100%;padding-bottom: 80px}
.choose_plan_wrp .chsplnwrp {width:100%;padding-right: 16px;overflow: auto;}
.choose_plan_wrp .chsplndtable {display: table;width: 100%;font-size:12px}
.choose_plan_wrp .chsplndcell {display: table-cell;vertical-align: middle;padding-right: 3px;font-size:12px}
.choose_plan_wrp .chsplndcell.colomt1 {min-width: 140px;width: 39%;}
.choose_plan_wrp .chsplndcell.colomt1 .conttd {justify-content: flex-start;}
.choose_plan_wrp .chsplndcell.colomt1 .conttd:nth-child(2n + 1) {background-image: linear-gradient(to right, #ededed 0%, rgba(240, 240, 240, 0) 82%);}
.choose_plan_wrp .chsplndcell.colomt2 {position: relative;  }
.choose_plan_wrp .chsplndcell.colomt2:before {content: '';position: absolute; background-image: linear-gradient(to bottom, #f8f8f8 9%, #fafafa 81%, rgba(255, 255, 255, 0));width: calc(100% - 3px);height: 100%;z-index: -1; left: 0;top: 0;}
.choose_plan_wrp .chsplndcell.colomt2.active:before {content: '';position: absolute;background-image: linear-gradient(to bottom, #ebfaff 9%, #ebfaff 81%, rgba(235, 250, 255, 0));width: calc(100% - 3px);height: 100%;z-index: -1; left: 0;top: 0;}
.choose_plan_wrp .chsplndcell.colomt2.active .headtd {background-color: #0076d7;}
.choose_plan_wrp .chsplndcell .headtd {border-radius: 10px 10px 0 0; background-color: #ededed; height: 30px; text-align: center; vertical-align: middle; display: inline-block; padding-top: 7px; width: 100%;font-size:12px;color: #111;}
.choose_plan_wrp .chsplndcell.colomt2 .headtd {background-color: #696969;color:#fff;}
.choose_plan_wrp .chsplndcell .conttd {padding: 16px 10px;height:60px;display: inline-flex;
    align-items: center; justify-content: center;  width: 100%;}
.choose_plan_wrp .chsplndcell .conttd.roww1 {}
.choose_plan_wrp .chsplndcell .conttd.roww2, .choose_plan_wrp .chsplndcell.colomt2 .conttd.roww2 {height: 36px}
.choose_plan_wrp .chsplndcell .conttd.roww3, .choose_plan_wrp .chsplndcell.colomt2 .conttd.roww3  {height: 36px}
.choose_plan_wrp .chsplndcell .conttd.roww4, .choose_plan_wrp .chsplndcell.colomt2 .conttd.roww4  {height: 70px;}
.choose_plan_wrp .chsplndcell .conttd.roww5, .choose_plan_wrp .chsplndcell.colomt2 .conttd.roww5  {height: 50px;}
.choose_plan_wrp .chsplndcell .conttd.roww6, .choose_plan_wrp .chsplndcell.colomt2 .conttd.roww6  {height: 36px}
.choose_plan_wrp .chsplndcell .conttd.roww7, .choose_plan_wrp .chsplndcell.colomt2 .conttd.roww7  {height: 36px}

.choose_plan_wrp .green_tick_icon {background:url('https://akam.cdn.jdmagicbox.com/images/icontent/seller/green_tick_icon.svg') no-repeat; width:16px; height: 16px; display: inline-block;}
.choose_plan_wrp .closelgrey-icn {background:url('https://akam.cdn.jdmagicbox.com/images/icontent/seller/closelgrey-icn.svg') no-repeat; width:16px; height: 16px; display: inline-block;}
.choose_plan_wrp .chsplndcell.colomt2 .conttd { text-align:center;height:60px;}
.greentabbx{  width: 43px; height: 22px; padding: 2px 8px; border-radius: 12px; border: solid 1px #18a160; background-color: #18a161;text-align:center; }
.white_right_icn {background:url('https://akam.cdn.jdmagicbox.com/images/icontent/seller/white_right_icn.svg') no-repeat; width:6px; height: 10px; display: inline-block;margin-left: 7px;}
/*PlanTotalbbx*/
.choose_plan_wrp .planselbxwrp {display: flex;width:100%; margin-top:30px;padding:0 16px; overflow: auto;}
.choose_plan_wrp .planselbx {min-width: 86px;width:auto; height: 92px; margin: 10px 10px 0 0; padding: 0 0 10px; border-radius: 6px; border: solid 1px #f4f4f4; background-color: #fff;display: inline-block;position: relative;overflow: hidden;}
.choose_plan_wrp .planselbx:last-child { margin-right: 0;}
.choose_plan_wrp .planselbx .headbx {min-width: 86px; height: 24px; padding: 7px 10px; border-radius: 0; background-color: #f4f4f4;font-size: 9px;}
.choose_plan_wrp .planselbx .contbx {padding:8px 10px 5px;}
.choose_plan_wrp .planselbx .contbx .rsicn {position: relative; top:-8px;}
.choose_plan_wrp .planselbx .contbx .totlbx{    display: flex;    align-items: self-end;}
.choose_plan_wrp .planselbx .contbx .totlbx .gstTx {position: relative;    top: -2px;}
.choose_plan_wrp .planselbx.active { border: solid 1px #0076d7;}
.choose_plan_wrp .planselbx.active .headbx { background-color: #0076d7;color:#fff;}
.choose_plan_wrp .planselbx.active .contbx .titl {color:#0076d7;}
.ftr_btn.posRelBtn {position: relative;box-shadow: none; margin-top: 30px;}
.choose_plan_wrp .notebxwrp {padding: 16px }
/* Choose Plan Details */
/* Thanku */
.thankuwpr{align-items: center; justify-content: center; height: calc(100vh - 100px); display: flex; flex-direction: column;}
.helpsucess{background-image: url('https://akam.cdn.jdmagicbox.com/images/icontent/newwap/menuimg/helpsucess.svg'); background-repeat: no-repeat; width: 120px; height: 120px; display: inline-block; background-position: center; background-size: 100% auto;}
.thankuiconwpr{display: block; padding: 50px 20px 20px; text-align: center; font-size: 0;}
.thankutextprnt{display: block; text-align: center; padding: 0 40px;}
.thankutext{display: block; text-align: center; }
.thankusum{display: block; text-align: center; }
/* Thanku */
/*detail popup*/
.detail_popup_wpr{position: fixed; left: 0%; top: 0; right: 0; height: 100%; width: 100%; background-color: rgba(0, 0, 0, 0.6); z-index: 11;}
.detail_popup_outer{position: fixed; left: 0%; bottom: 0; right: 0; width: 100%; background-color: #fff; border-radius: 10px 10px 0 0;}
.detail_popup_btn button{border: 0; height: 40px; display: inline-flex; justify-content: center; align-items: center; background-color: #0076d7; border-radius: 6px; margin: 0 10px; cursor: pointer;}
.rlbtn{width: 100% !important; flex-direction: column; margin: 5px !important; display: flex; }
.lbtn{color: #414e5a !important; background: #fff !important; border: 1px solid #dde2e2 !important;}
/*detail popup*/
/* Error text */
.apierrortxt{font-size: 12px; color: red; padding: 10px 20px 0px 20px; text-align: center;}
/* Error text */
/* choose single plan */
.choose_single_plan_wpr{display: block;}
.pl-24{padding-left: 24px;}
.pr-24{padding-right: 24px;}
.setupplan_wpr{display: block; position: relative;}
.setupplan_wpr:before{content: ''; position: absolute; background-color: #f0f9ff; border: solid 1px #dfedf7; border-radius: 10px; width: 100%; height: calc(100% - 40px); left: 0; bottom: 0;}
.setupplan_outer{display: flex; justify-content: center; align-items: center; flex-direction: column; padding: 12px 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); background-image: linear-gradient(113deg, #1763bc 14%, #0086d9 84%); border-radius: 10px; position: relative;}
.setupplan_text{position: relative;}
.setupplan_text:before{content: ''; position: absolute; left: -30px; top: 50%; transform: translateY(-50%); height: 1px;
  border-radius: 6px; width: 30px; background-image: linear-gradient(to right, rgba(200, 230, 250, 0) 1%, rgba(244, 250, 255, 0.24) 34%, rgba(244, 250, 255, 0.24) 66%, rgba(220, 238, 250, 0));}
.setupplan_text:after{content: ''; position: absolute; right: -30px; top: 50%; transform: translateY(-50%); height: 1px;
  border-radius: 6px; width: 30px; background-image: linear-gradient(to right, rgba(200, 230, 250, 0) 1%, rgba(244, 250, 255, 0.24) 34%, rgba(244, 250, 255, 0.24) 66%, rgba(220, 238, 250, 0));}
.setupplan_amnt{font-size: 46px; display: inline-flex; text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);}
.setupplan_amnt span{padding-top: 8px;}

.benefitstext{display: flex; justify-content: flex-start; align-items: center; position: relative; padding-left: 13px; min-height: 27px; border-radius: 6px;
  background-image: linear-gradient(to right, #dceefa 1%, rgba(220, 238, 250, 0));}
.planlist{display: block;}
.planlist li{display: flex; justify-content: flex-start; align-items: center; position: relative; width: 100%; margin-bottom: 24px; padding-left: 26px;}
.planlist li:before{content: ''; position: absolute; left: 0; top: 2px; width: 16px; height: 16px; background-image: url(https://akam.cdn.jdmagicbox.com/images/icons/genio/green_tick.svg); background-repeat: no-repeat; background-size: 100%;}
.planlist li:last-child{margin-bottom: 0;}
.notetext{display: block; padding: 8px 12px;}
.notetext{display: block; position: relative; border-radius: 6px; border: solid 1px #efefef; background-color: #fff;}
.notetext li{display: flex; justify-content: flex-start; align-items: center; padding-left: 15px; flex-direction: row; position: relative;}
.notetext li:before{content: "*"; position: absolute; left: 0; top: 2px; color: #777;}
.planbrakeup_wpr{display: block;}
.planbrakeup_wpr li{display: flex; justify-content: space-between; align-items: center; flex-direction: row;}
.nextline{display: block;}
.planbrakeup_final{display: flex; justify-content: space-between; align-items: center; flex-direction: row; border-top: 1px solid #eaeaea;}
.maybelater_wpr{display: flex; justify-content: space-between; align-items: center; flex-direction: row; box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.16); background-color: #fff; position: fixed; left: 0; bottom: 0; right: 0; width: 100%;}
.maybelater_wpr button{display: inline-flex; justify-content: center; align-items: center; height: 40px; border-radius: 6px;
  background-color: #0076d7; border: 0; width: calc(60% - 5px); cursor: pointer;}
button.maybelater_btn{border: solid 1px #e5e5e5; background-color: #fff; width: calc(40% - 5px);}


/* choose single plan */
