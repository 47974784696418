@charset "utf-8";
* {box-sizing: border-box;outline: medium none; text-decoration:none;}
img {max-width:100%; border: 0;vertical-align: middle;}
a{text-decoration: none;border: 0;outline: none; cursor: pointer;}
a:hover, a:active, a:visited, a:focus {text-decoration: none; outline: 0 none;}
ul, ol, li{ list-style:none; margin:0; padding:0; background:none;}
body{margin:0 auto; padding:0;-webkit-overflow-scrolling: touch; touch-action: manipulation;}
hr{ border: none;}
body, input, textarea, select, button {text-rendering: optimizelegibility;font-family: 'Roboto', sans-serif;-webkit-tap-highlight-color:transparent;}
input[type=number].input-number–noSpinners {-moz-appearance: textfield;}
input[type=number].input-number–noSpinners::-webkit-inner-spin-button,
input[type=number].input-number–noSpinners::-webkit-outer-spin-button{-webkit-appearance: none;margin: 0;}
select::-ms-expand {display: none;}
select {-webkit-appearance: none; -moz-appearance: none; appearance: none; } 
p{margin: 0 0 10px;}
h1, h2, h3, h4, h5, h6{font-size:100%;margin:0;padding:0}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {display: block;}
input::-ms-clear {width : 0;height: 0;}
html{height: 100vh;}
html,body{width: 100%; max-width: 100%; border: 0;}

.container{max-width: 990px; width: 100%; margin: auto; height: 100%; position: relative;}
/*nav*/
.main_nav_wpr{display: block; width: 100%; padding: 24px 20px;}
.main_nav_outer{display: flex; justify-content: space-between; align-items: center; flex-direction: row; font-size: 0;}
.justdial_logo{height: 30px; width: 200px; background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/jdbusiness_logo.svg); display: inline-block; background-position: center; background-repeat: no-repeat; }
.loginlink{display: inline-flex; justify-content:flex-start; align-items: center; cursor: pointer; height: 33px; padding: 0 18px; border-radius: 6px; border: solid 1px #0f7dd8; background-color: #fff; font-size: 14px;
  font-weight: 500; color: #0f7dd8;}
.loginlink span{padding-left: 4px;}
.create_account_link{margin-left: 20px; display: inline-flex; justify-content:flex-start; align-items: center; height: 33px; padding: 0 18px; border-radius: 6px; background-color: #0f7dd8; cursor: pointer; color: #fff; font-size: 14px; font-weight: 500;}
.main_nav_outer .logoSubnavbx {display: flex; align-items: center;}
.main_nav_outer .headsubnav {padding-left:30px;}
.main_nav_outer .headsubnav .txbx{font-size:15px; color:#111; font-weight: 500;padding-right:15px;cursor:pointer;}
/*New design */
.main_nav_wpr.header {    border-bottom: 1px solid rgb(0 0 0 / 10%);}
.main_nav_wpr.header .containernew {padding: 0}
.main_nav_wpr.header .rightbx {display: inline-flex; align-items: center;}
.hl_login_section {display: inline-flex; vertical-align: middle; cursor: pointer; position: relative; -webkit-align-items: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; height: auto;}
.hl_login_dropdown:before {
    position: absolute; content: ''; border-bottom: 15px solid #fff; border-right: 12px solid transparent; border-left: 12px solid transparent; top: -12px; right: 15px; }
.hl_login_text {display: inline-flex; align-items: center;    margin-left: 10px; }
.profile_image {width: 40px; height: 40px; border-radius: 40px; margin-right: 12px; object-fit: cover;}
.hl_drop_icon {background: url(https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/hl-dropsmll_icon.svg); display: inline-block; width: 14px; height: 30px; background-repeat: no-repeat; background-position: center; }
.hl_login_dropdown {position: absolute; width: 268px; box-shadow: 0 0 20px 0 rgb(0 0 0 / 16%); background-color: #fff; z-index: 5; right: 0; top: 47px; }
.hl_login_dropdown .login_item {border: solid 1px #f2f2f2; text-align: left; cursor: pointer; }
.hl_login_dropdown .login_item_row {display: inline-flex; width: 100%; align-items: center; }
.hl_login_dropdown .login_item_text {font-size: 13px; font-weight: 600; color: #111; text-transform: uppercase;width:100%;padding:16px 20px;cursor:pointer}
/*New design */
/*nav*/

.containernew {max-width: 1366px; width: 100%; margin: auto; height: 100%; position: relative;}

/*Banner*/
.banner_wpr{background-color: #0f7dd7;/*background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/jdseller_bnr.png);*/background-size: cover;height: 455px;display: inline-block;background-repeat: no-repeat;width: 100%;text-align: center;position: relative;}
.bnrimg{background: #fff; position: absolute;height: 480px;margin: 0 auto;width: 1340px;left: 50%;transform: translateX(-50%);}
.bnrimg img{width: 100%; height: 100%; object-fit: contain;}
.banner_container{position: absolute; left: 14%; bottom: 0; z-index: 2;}
.startselling_search_wpr{position: absolute; display: flex; justify-content: flex-start; flex-direction: column; bottom: 70px; left: 35px;}
.startselling_search_outer{display: flex; justify-content: flex-start; align-items: center; width: 405px; height: 54px; padding: 2px 3px 2px 17px; border-radius: 6px; background-color: #fff; position: relative;}
.startselling_search_outer input{width: 209px; padding-left: 13px;border: 0;font-size: 15px; color: #111;}
.startselling_search_outer input::placeholder{font-size: 15px;color: #777;}
.startselling_search_outer .cntrycode{display: inline-block; font-size: 15px; color: #111;}
.startselling_search_outer button{width: 150px; height: 50px; padding: 0; border-radius: 6px; background-color: #ff6c00; color: #fff; border: 0;font-size: 17px;font-weight: 500;cursor: pointer;}
.regtext{display: block; font-size: 16px;color: #fff; margin-top: 10px; opacity: 0.71; text-align: left;}
/*New Design Changes */
.banner_wpr.newdes {background: #fff;margin-top: 28px;}
.bnrleftprt {display: inline-block;width: 70%; height: 402px;background:#DBF5FF;position: relative;border-radius: 6px; }
.bnrrightprt {display: inline-block;width: calc(30% - 20px); height: 402px;background:#e6f3ff;margin-left: 20px; position: relative;border-radius: 6px;}
.banner_wpr.newdes .bgimg{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/rs-box-banner1_2x.png);background-repeat: no-repeat; background-size: 435px; position: absolute; width: 435px; height: 430px; right: 0; bottom: -28px; }
.bnrleftprt .bnrtextbox {position: absolute; padding-left: 50px; padding-top: 60px; text-align: left; padding-right: 226px; }
.bnrleftprt .bnrtextbox .h1text {font-size: 54px}
.bnrleftprt .orngbtn {cursor: pointer;width: 153px;height: 50px;padding: 14px 10px;background-color: #ff6c00;border: 0; color: #fff;margin-top: 58px;font-size: 22px;}
.bnrrightprt .bnrtextbox {position: absolute; padding-left: 40px; padding-top: 40px; text-align: left; padding-right: 40px; }
.bnrrightprt .bnrtextbox .h1text {font-size: 47px;}
.bnrrightprt .blkbtn {cursor: pointer;width: 146px;height: 50px;padding: 14px 10px;background-color: #111;border: 0; color: #fff;margin-top: 25px;font-size: 22px;}
.bnrrightprt .sublistul {width:100%;}
.bnrrightprt .sublistul .subli {    width: 100%;  display: flex; align-items: center;margin-bottom:10px;background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0) 81%); border-radius: 6px; padding: 12px 10px 12px 18px;}
.bnrrightprt .sublistul .subli .imgbx {width:26px;height:26px;}
.bnrrightprt .sublistul .subli .imgbx .nocolchrgicn{background:url('https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/nocollection.svg') no-repeat;width:26px;height:26px;display: inline-block;vertical-align: middle; }
.bnrrightprt .sublistul .subli .imgbx .freeshipicn{background:url('https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/freeshipping.svg') no-repeat;width:26px;height:26px;display: inline-block;vertical-align: middle; }
.bnrrightprt .sublistul .subli .conbx {padding-left:15px;color: #193853;font-size: 17px;font-weight: 500;}
/*New Design Changes */
/*Banner*/


/*customer stories*/
.customerstories_wpr{display: block; background-color: #fff; padding-bottom: 50px; padding-top: 20px;}
.customerstories_outer{display: flex; justify-content: center; align-items: center; flex-direction: column;}
.cs_title{display: inline-block; font-size: 14px; font-weight: 500; color: #777;}
.sellertext{display: inline-block; font-size: 30px; font-weight: 500; color: #111; margin-top: 5px;}
.sellercard_wpr{display: flex;  width: 100%; margin-top: 30px;}
.sellercard{display: inline-block; width: 400px; padding: 32px 52px 45px 32px; border-radius: 10px; box-shadow: 0 10px 0 0 #dbf5ff; background-color: #fff; margin-right: 35px; border: 1px solid #f0f0f0;  border-bottom: 0;}
.sellercard:nth-child(2n) {box-shadow: 0 10px 0 0 #dceaf8;border: 1px solid #f0f0f0; }
.sellercard:last-child{margin-right: 0;}
.sellerimg_wpr{display: flex; justify-content: space-between; align-items: center;}
.rightquotationsign{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/jdseller_rightquotationsign.svg); width: 63px; height: 51px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.sellerimg{display: inline-block; width: 80px; height: 80px; border-radius: 6px; border: solid 1px #f0f0f0; background-color: #777; }
.seller_comment{display: block; padding: 10px 0 12px; font-size: 18px; font-style: italic; color: #111;}
.seller_dtl_text{display: flex; justify-content: flex-start; flex-direction: column;}
.seller_name{display: block; font-size: 16px; font-weight: 500; color: #111;}
.seller_store{display: block; font-size: 14px; color: #777; padding-top: 5px;}
.carousel_nav{display: flex; justify-content: center; align-items: center; flex-direction: row; margin-top: 37px;}
.carousel_nav span{display: inline-block; width: 11px; height: 6px; background-color: #777; border-radius: 6px; margin-right: 6px;}
.carousel_nav span:last-child{margin-right: 0;}


/*customer stories*/


/*how to sell*/
.howtosell_wpr{display: block; background-color: #e8f6ff; padding: 62px 0 70px;}
.howtosell_outer{display: flex; justify-content: center; flex-direction: column; text-align: center;}
.howtosell_title{display: inline-block; font-size: 14px; font-weight: 500; color: #111;}
.howtosell_text{display: inline-block; font-size: 30px; font-weight: 500; color: #111; padding-top: 7px;}
.howtosell_steps{display: flex; justify-content: space-between; flex-direction: row;}
.howtosell_steps_content{display: inline-block; justify-content: center; align-items: baseline; flex-direction: column; margin-top: 32px; position: relative; padding: 0 25px; width: 25%;}
.howtosell_steps_content:after{content: ''; background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/jdseller_borderline_down2x.svg); position: absolute; right: 0; top: 25px; width: 104px; height: 34px;transform: translateX(50%); background-repeat: no-repeat; background-size: 100%;}
.howtosell_steps_content:nth-child(2):after{content: ''; background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/jdseller_borderline_up2x.svg); position: absolute; right: 0; top: 25px; width: 104px; height: 34px;transform: translateX(50%); background-repeat: no-repeat; background-size: 100%;}
.howtosell_steps_content:last-child:after{display: none;}
.stepicon{display: inline-block; width: 80px; height: 80px;}
.stepicon img{width: 100%; height: 100%; object-fit: contain;}
.howtosell_steps_title{display: block; font-size: 20px; font-weight: 500; color: #111; text-align: center; padding-top: 24px;}
.howtosell_steps_para{display: block; font-size: 16px; color: #777; padding-top: 10px;}
/* New Design Changes  */
.howtosell_wpr.newdes {background-color: #f8f8f8;padding: 50px 0;}
.howtosell_wpr.newdes .ultemplwrp {width: 100%; height: auto; margin: 30px 0 0; padding: 20px 20px 0; border-radius: 10px; background-color: transparent; display:flex;}
.howtosell_wpr.newdes .ultemplwrp .ultempl{display: flex; flex-flow: wrap; justify-content: space-between;}
.howtosell_wpr.newdes .ultemplwrp .ultempl .litempl {display:inline-block; width:50%;height: 173px;margin-bottom: 30px;     display: inline-block; width: calc(50% - 12px); margin-bottom: 30px; border-radius: 20px; border: solid 1px #eaeaea; background-color: #fff; padding: 30px 30px 32px;}
.howtosell_wpr.newdes .ultemplwrp .ultempl .litempl .imgbxwrp {display: table-cell;vertical-align: middle;}
.howtosell_wpr.newdes .ultemplwrp .ultempl .litempl .imgbxwrp .imgbx {width: 80px; height: 80px;}
.howtosell_wpr.newdes .ultemplwrp .ultempl .litempl .contwrp {display: table-cell;vertical-align: middle;text-align: left;}
.howtosell_wpr.newdes .startselling_btn button {background-color: #111; border-radius: 6px;}
/* New Design Changes  */
 
/*how to sell*/


/*seller info*/
.seller_info_wpr{display: block;}
.seller_info_outer{display: flex; justify-content: start; padding-top: 30px; padding-bottom: 70px;}
.seller_info{display: block; position: relative; padding: 56px 275px 66px 40px;
  border-radius: 10px; background-color: #fff9eb;width: calc(100% - 260px);}
.seller_info_title{display: block; font-size: 14px; font-weight: 500; color: #111;}
.seller_info_boldtext{display: block; padding-top:36px;font-size: 28px; color: #111; font-weight: bold;}
.seller_info_list{display: block; padding-top: 30px;}
.seller_info_list li{display: block; margin-bottom: 20px;}
.seller_info_list li:last-child{margin-bottom: 0;}
.seller_info_list_title{display: block; position: relative; font-size: 18px; font-weight: 500; color: #111; padding-left: 22px;}
.seller_info_list_title:before{content: ''; width: 12px; height: 12px; display: inline-block; background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/jdseller_polygon.svg); background-repeat: no-repeat; background-size: 100%; position: absolute; left: 0; top: 3px;}
.seller_info_list_text{display: block; margin-top: 10px; font-size: 15px; font-weight: normal; color: #111;}
.disblock{display: block;}
.startselling_btn{display: block; margin-top: 30px;}
.startselling_btn button{display: inline-flex; justify-content: center; align-items: center; border-radius: 6px;
  background-color: #ff7427; height: 40px; cursor: pointer; width: 200px; border: 0; color: #FFF; font-size: 16px;
  font-weight: 500;}
.sellerbig_img{position: absolute; right: -260px; top: 57px; width: 480px; height: 585px; background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/seller_img.png); background-size: 100%; background-repeat: no-repeat;}
.sellerbig_img img{width: 100%; height: 100%; object-fit: contain;}


/*seller info*/

/*sellonjdmarketplce*/
.sellonjdmarketplce .sellerbig_img { top: 78px;}
.sellonjdmarketplce .seller_info_boldtext {padding-top: 0;}
.sellonjdmarketplce .ultemplwrp {width: 430px; height: auto; margin: 30px 0; padding: 20px ; border-radius: 10px; background-color: #fff4db;}
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl{ display: table; table-layout: fixed;margin-bottom: 30px; }
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl .imgbxwrp {display: table-cell;vertical-align: middle;}
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl .imgbxwrp .imgbx {width: 80px; height: 80px;}
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl .contwrp {display: table-cell;vertical-align: middle;}
.seller_info_wpr.sellonjdmarketplce .startselling_btn button {background-color: #111; border-radius: 6px;}
/*customer experience */
.seller_info_wpr.customerexp_wpr {padding-top:60px;}
.seller_info_wpr.customerexp_wpr .seller_info_boldtext {font-size:36px;}
.seller_info_wpr.customerexp_wpr .seller_info_medtext {    margin-top: 13px;}
.seller_info_wpr.customerexp_wpr .seller_info_outer{justify-content: end; padding-top: 0; padding-bottom: 0; }
.seller_info_wpr.customerexp_wpr .seller_info{background-color: #e8fff9; padding: 40px 20px 66px 270px; }
.seller_info_wpr.customerexp_wpr .sellerbig_img{left: -292px; top: 100px;width: 529px; height: 549px;/*background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/monitor_img.png);*/ background-size: 100%; background-repeat: no-repeat;}
.seller_info_medtext{display: block; font-size: 20px; font-weight: 500; color: #111; margin-top: 30px;}
.downloadselling_btn{display: block; margin-top: 30px;}
.downloadselling_btn button{display: inline-flex; justify-content: center; align-items: center; border-radius: 6px;
  background-color: #0076d7; height: 40px; cursor: pointer; width: 200px; border: 0; color: #FFF; font-size: 16px;
  font-weight: 500;}
.seller_info_wpr.customerexp_wpr .seller_info_boldtext{font-size: 30px; font-weight: bold; margin-top: 0;    padding-top: 20px;}
.seller_info_wpr.customerexp_wpr .seller_info{width: calc(100% - 227px);}
.video_container{position: absolute; width: 74%; left: 13%; bottom: 29%;}
.video_container video{width: 100%;}
.seller_info_wpr.customerexp_wpr .ultemplwrp {width: 470px; height: 470px; margin: 30px 0 0; padding: 20px; border-radius: 10px; background-color: #def5ef;}
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl{ display: table; table-layout: fixed;margin-bottom: 30px; }
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl .imgbxwrp {display: table-cell;vertical-align: middle;}
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl .imgbxwrp .imgbx {width: 80px; height: 80px;}
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl .contwrp {display: table-cell;vertical-align: middle;}
.seller_info_wpr.customerexp_wpr .downloadselling_btn button {background-color: #111; border-radius: 6px;}

.mob_img{display: none;}

.sellerbig_img.customerexp_img{background-image: none;}

/*customer experience */


/*Shopping Fee Structure  */
.seller_info_wpr.shopFeeStruct .seller_info_boldtext {font-size:36px;padding-top:25px;}
.seller_info_wpr.shopFeeStruct .seller_info_outer {padding-bottom: 40px;padding-top: 70px;}
.seller_info_wpr.shopFeeStruct .seller_info {background-color: #fff7f9;padding-top: 30px;width: 800px;}
.seller_info_wpr.shopFeeStruct .sellerbig_img{    background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/banner-fee_d.png);    right: -200px;top:100px;}
.seller_info_wpr.shopFeeStruct .ultemplwrp { width: 453px;height: auto; margin: 30px 0;padding: 20px; border-radius: 10px; background-color: #f7eff1;  }
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl{ display: table; table-layout: fixed;margin-bottom: 30px; }
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl .imgbxwrp {display: table-cell;vertical-align: middle;}
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl .imgbxwrp .imgbx {width: 80px; height: 80px;}
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl .contwrp {display: table-cell;vertical-align: middle;}
.seller_info_wpr.shopFeeStruct .startselling_btn button {background-color: #111; border-radius: 6px;}
/*Shopping Fee Structure  */



/*common links*/
.commonlinks_outer{display: block; padding: 0 0 50px 0;}
.commonlinks_outer ul{display: block; font-size: 0;}
.commonlinks_outer li{display: inline-flex; height: 37px; padding: 0 25px; cursor: pointer; border-radius: 4px;
  border: solid 1px #eee; font-size: 18px; font-weight: normal; color: #111; justify-content: center; align-items: center; margin-right: 25px;}

/*common links*/


/*register now*/
.register_wpr{display: block; width: 100%; background-color: #0f7dd8;}
.register_outer{display: flex; justify-content: flex-start; align-items: center; padding: 35px 30px;}
.register_title{display: block; font-size: 30px; font-weight: 500; color: #fff;}
.register_text{display: block; font-size: 18px; font-weight: normal; color: #fff; padding-top: 8px;}
.register_btn{padding-left: 70px;}
.register_btn button{display: inline-flex; width: 150px; height: 50px; border-radius: 6px; box-shadow: -2px 1px 3px 0 rgba(0, 0, 0, 0.27); background-color: #fff; font-size: 17px; font-weight: 500; text-align: center;
  color: #0f7dd8; border: 0; justify-content: center; align-items: center; cursor: pointer;}
/*register now*/


/* commission fee */
.pl-20{padding-left: 20px;}
.text_center{text-align: center;}
.mt-70{margin-top: 70px;}
.color777{color: #777;}
.commission_fee_wpr{display: block; background-color: #f8f8f8; }
.commission_fee_outer{display: block; padding: 50px 0;}
.commission_fee_title{display: block; color: #777; font-size: 14px; font-weight: 500; text-align: center;}
.commission_fee_text{display: block; color: #111; font-size: 30px; font-weight: 500; text-align: center;}
.commission_chart_outer{display: flex; justify-content: flex-start; flex-direction: row; position: relative; margin-top: 50px;}
.commission_chart_tabs{display: inline-block; width: 200px; }
.commission_chart_tabs li{display: inline-flex; width: 100%; height: 61px; border-radius: 6px 0 0 6px;  border: solid 2px #eaeaea; border-width: 2px 0 2px 2px; background-color: #fff; cursor: pointer; font-size: 20px; font-weight: 500; color: #111; justify-content: flex-start; align-items: center; padding-left: 15px; margin-bottom: 8px;}
.commission_chart_tabs li.act{color: #0076d7; position: relative; width: calc(100% + 2px);}
.commission_chart_tabs li.act:before{content: ''; left: 15px; bottom: 10px; position: absolute; background-color: #0076d7; height: 2px; width: 13px;}
.commission_chart_content{display: inline-block; width: calc(100% - 200px); border-radius: 0 10px 10px 10px; border: solid 2px #eaeaea; background-color: #fff; padding: 24px 30px;}
.commission_chart_title{display: block; font-size: 24px; font-weight: bold; color: #111;}
.comisn_according_title{display: flex; justify-content: flex-start; align-items: center; flex-direction: row; color: #111; background-color: #fff; font-size: 16px; font-weight: bold; border-radius: 6px; border: solid 1px #eaeaea; height: 54px; padding: 0 20px; position: relative; cursor: pointer;}
.downarrow{background-image: url(http://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/hl_select_downarrow_icon.svg); width: 20px; height: 20px; display: inline-block; background-size: 100%; background-repeat: no-repeat;}
.comisn_according_title .downarrow{position: absolute; right: 20px; top: 50%; transform: translateY(-50%); background-position: center;
    background-size: 80%;}
.comisn_according_outer.act .comisn_according_title .downarrow{transform: rotate(180deg); background-position: bottom; margin-top: -2px;}
.comisn_according_outer{display: block; margin-top: 20px;}
.comisn_according_outer.act .comisn_according_title{border-radius: 6px 6px 0 0; border-bottom: 0;}
.comisn_according_content{display: block; border: solid 1px #eaeaea; background-color: #fafafa; border-radius: 0 0 6px 6px; padding: 20px 30px;}
.categoryflex{display: flex; justify-content: space-between; align-items: center; flex-direction: row; font-size: 14px; color: #777;}
.categoryflex_l, .categoryflex_r{width: 50%;}
.categoryflex_l{padding-left: 20px;}
.categoryflex_r{text-align: center;}
.comisn_inner_according_outer{display: block; margin-top: 20px;}
.comisn_inner_according_title{display: flex; justify-content: flex-start; align-items: center; flex-direction: row; color: #111; background-color: #fff; font-size: 15px; font-weight: bold; border-radius: 6px; border: solid 1px #eaeaea; height: 48px; padding: 0 20px; position: relative; cursor: pointer;}
.comisn_inner_according_outer.act .comisn_inner_according_title{border-radius: 6px 6px 0 0; border-bottom: 0;}
.comisn_inner_according_content{display: block; border: solid 1px #eaeaea; background-color: #fff; border-radius: 0 0 6px 6px; padding: 20px 20px 0;}
.comisn_inner_according_title .downarrow{position: absolute; right: 20px; top: 50%; transform: translateY(-50%); background-position: center; background-size: 80%;}
.comisn_inner_according_outer.act .comisn_inner_according_title .downarrow{transform: rotate(180deg); background-position: bottom; margin-top: -4px;}
.inner_according_hdr{display: flex; justify-content: space-between; flex-direction: row; border-radius: 6px; padding: 16px 20px;
  background-color: #f6f6f6; font-size: 0;}
.inner_according_left{width: 40%;}
.inner_according_right{width: 60%;}
.inner_according_right ul{display: table; table-layout: fixed; width: 100%;}
.inner_according_right li{display: table-cell; vertical-align: middle; font-size: 13px; color: #111; text-align: center;}

.inner_according_row{display: flex; justify-content: space-between; flex-direction: row; border-radius: 0; padding: 16px 20px;
  border-bottom: 1px solid #eee;}
.inner_according_row:last-child{border-bottom: 0;}
.inner_according_row_left{width: 40%; font-size: 14px; color: #111;}
.inner_according_row_right{width: 60%;}
.inner_according_row_right ul{display: table; table-layout: fixed; width: 100%;}
.inner_according_row_right li{display: table-cell; vertical-align: middle; font-size: 13px; color: #111; text-align: center;}
.mt-25{margin-top: 25px;}
.closefee_chart{display: block; border-radius: 10px; border: solid 1px #eee;}
.closefee_hdr{display: table; table-layout: fixed; width: 100%; border-radius: 10px 10px 0 0; background-color: #fcfcfc; border-bottom: 1px solid #eee; }
.closefee_th{display: table-cell; vertical-align: middle; padding: 16px 20px; font-size: 14px; color: #111; font-weight: 500; text-align: center;}
.closefee_th:first-child{border-right: 1px solid #eee;}
.closefee_row{display: table; table-layout: fixed; width: 100%; background-color: #fff; border-bottom: 1px solid #eee; }
.closefee_row:last-child{border-bottom: 0; border-radius: 0 0 10px 10px;}
.closefee_td{display: table-cell; vertical-align: middle; padding: 16px 20px; font-size: 13px; color: #111; font-weight: 500; text-align: center;}
.closefee_td:first-child{border-right: 1px solid #eee;}


/* commission fee */

/* Footer Section */
.container_cms {max-width: 1360px; width: 100%; height: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }
.jdbusiness_img{background-image: url('https://akam.cdn.jdmagicbox.com/images/icontent/hyperlocal/jdbusiness_logo-bw.svg');height: 30px;width: 200px;display: inline-block;background-size: 100%;margin: 0 0 15px 0;background-repeat: no-repeat; }
.footer_section{padding:100px 0;background-color: #f8f8f8;}
.footerbox{font-size:0;width:100%;display:flex}
.inner_footerbox{display:inline-block;width:calc((100% / 3) - 66.66px);margin-right:70px;border-bottom:#ccc solid 1px;padding:0 0 60px 0}
.inner_footerbox:last-child{margin-right:0;width: 40%;border-bottom: 0;}
.footer_row1{font-size:15px;color:#111;margin:0 0 10px 0}
.footer_row2{font-size:20px;font-weight:400;color:#111;margin:0 0 40px 0}
.footer_row3{display:flex;justify-content:flex-start;margin:40px 0 0}
.footer_row3 .ctwrap{font-size:15px;color:#111;display:inline-flex;align-items:center;cursor:pointer;width: 50%}
.footer_row3 .fdwrap{font-size:15px;color:#111;cursor:pointer}
.cnfeediconBx{height:26px;width:26px;display:inline-block;background-repeat:no-repeat;background-position:center;margin-right:10px}
.contactusicon{background-image:url(https://akam.cdn.jdmagicbox.com/images/icontent/cms/mic_icon.svg)}
.feedbackicon{background-image:url(https://akam.cdn.jdmagicbox.com/images/icontent/cms/chat_bubble_icon.svg)}
.abtkicon{background-image:url(https://akam.cdn.jdmagicbox.com/images/icontent/cms/about_icon.svg)}
.socialicon_box{height:35px;width:35px;display:inline-block;background-repeat:no-repeat;background-position:center;margin:0 10px 0 0}
.facebookicon{background-image:url(https://akam.cdn.jdmagicbox.com/images/icontent/cms/facebook_icon.svg)}
.twittericon{background-image:url(https://akam.cdn.jdmagicbox.com/images/icontent/cms/twitter_icon.svg)}
.instagramicon{background-image:url(https://akam.cdn.jdmagicbox.com/images/icontent/cms/insta_icon.svg)}
.youtubeicon{background-image:url(https://akam.cdn.jdmagicbox.com/images/icontent/cms/youtube_icon.svg)}
.copyright_wrapper{font-size:15px;color:#111;margin:25px 0 0;line-height:1.67;letter-spacing:normal;text-align:left}
.copyright_wrapper a{color:#111}
.linklist{display:flex;justify-content:flex-start;flex-wrap:wrap}
.linklist .linklist_li{display:inline-flex;width:calc(100% / 2)}
.linklist .linklist_li a{font-size:18px;color:#111;font-weight:500;margin:0 0 20px;}

/* footer section css End */

/*register now*/
@media only screen and (min-width: 960px){
.carousel_nav {display: none;}
}
@media only screen and (min-width: 1367px){
  .container {
    width: 990px;
  }

  .startselling_search_wpr{bottom: 70px;}
  .customerstories_wpr{padding-top: 20px;}
  
}



/* Smaller than standard 960 (devices and browsers) */
@media only screen and (min-width: 960px) and (max-width: 1200px)  {
  .container {
    width: 990px;
  }
  
}
  
/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (min-width: 769px) and (max-width: 959px) {
  .container {
    width: 990px;
  }

  .banner_wpr{height: 385px;}
  .bnrimg{height: 415px;}
}


/* your css rules for ipad portrait */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .bnrimg{height: 195px; width: 100%;}
  .sellerbig_img{background-size: contain; background-position: center;}
  .main_nav_wpr{padding: 24px 20px;}
  .banner_wpr{height: 195px;}
  .startselling_search_wpr{bottom: 10px;}
}

/* your css rules for ipad landscape */
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  .startselling_search_wpr{bottom: 20px;}
  .banner_wpr{height: 300px;}
  .bnrimg{height: 325px; width: 100%;}
  .main_nav_wpr{padding: 24px 0;}
}




@media only screen and (max-width: 767px) {
  .main_nav_outer .headsubnav {display: block; position: absolute; top: 39px; z-index: 20; background: #fff;  width: 200px; padding-bottom: 15px;transition: all 0.5s;left: -226px; }
  .main_nav_outer .headsubnav.active {left:-10px;}
    .main_nav_outer .headsubnav .txbx {width: 100%; display: flex; padding: 10px 0;}
  .justdial_logo {width: 90px;background-size: 90px;}
  .container{width:100%}
  .hidden_mob{display: none;}
  .create_account_link{font-size: 11px;height: 25px;padding: 0 10px;text-align: center;}
  .main_nav_wpr{padding: 7px 10px;}
  .hl_login_dropdown {top: 43px;}
  .profile_image {width: 25px;height: 25px}
  .containernew {padding: 0}
  /*banner*/
  .burgericon{height: 26px; width: 26px; background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/jdseller_more.svg); display: inline-block; background-position: center; background-repeat: no-repeat; cursor: pointer; margin-right: 10px;}
  .bnrimg{display: none;}
  .mob_login{border: 0; padding-right: 0; font-size: 12px;}
  .mob_login span{padding-left: 0;}
  .banner_wpr{height: 195px; background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/jdseller_bnr_mob.png); background-position:center;}
  .startselling_search_wpr{bottom: -30px; left: 0; z-index: 1; width: 100%;}
  .startselling_search_outer{border-radius: 6px; box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff; height: 40px; width: calc(100% - 40px); margin: 0 auto; padding-left: 12px; position: relative;}
  .startselling_search_outer input{width: 160px; font-size: 13px;}
  .startselling_search_outer button{width: 106px; height: 36px; font-size: 12px; position: absolute; right: 2px;}
  .startselling_search_outer input::placeholder{font-size: 13px;color: #777;}
  /*new*/
  .banner_wpr.newdes {margin-top: 0}
  .bnrleftprt {width: 100%;height: 190px; border-radius: 0}
  .banner_wpr.newdes .bgimg {width: 194px; height: 194px; background-size: 194px; bottom: -14px;}
  .bnrleftprt .bnrtextbox {padding-left: 20px;padding-top: 30px;padding-right: 100px}
  .bnrleftprt .bnrtextbox .h1text {font-size: 24px;}
  .bnrleftprt .orngbtn {width: auto; height: 28px; font-size: 12px; padding: 7px 10px; margin-top: 20px;}
  .bnrrightprt {width: calc(100% - 20px); margin: 20px 10px; padding: 15px 20px; height: 131px;}
  .bnrrightprt .bnrtextbox {position: relative;padding: 0;}
  .bnrrightprt .bnrtextbox .h1text {font-size: 22px;margin-bottom: 9px;}
  .bnrleftprt .bnrtextbox .h1text ~ .font30 {font-size:15px;}
  .bnrleftprt .font24, .bnrrightprt .font24 {font-size: 12px;} 
  .bnrrightprt .blkbtn {color: #0076d7; margin-top: 10px; padding: 0; background: transparent; width: auto; height: auto; border-bottom: 1px dashed #0076d7; font-size: 13px;}
  .bnrrightprt .sublistul .subli .conbx {font-size:11px;white-space: nowrap;padding-left: 6px;}
  .bnrrightprt .blkbtn {font-size:13px;font-weight: 700;}
  .bnrrightprt .sublistul .subli {width: auto; display: inline-flex;padding: 5px 6px;height: 26px;background:rgba(255,255,255,0.5);border-radius: 2px;}
  .bnrrightprt .sublistul .subli:first-child {margin-right: 10px;}
  .bnrrightprt .sublistul .subli .imgbx{width: 18px; height: 18px; }
  .bnrrightprt .sublistul .subli .imgbx .nocolchrgicn, 
  .bnrrightprt .sublistul .subli .imgbx .freeshipicn {width: 18px; height: 18px;background-size: 18px;   position: relative; top: -2px;}
    /*banner*/
  

  /*customer stories*/
  .customerstories_wpr{padding-top: 50px; padding-bottom: 30px;}
  .cs_title{font-size: 11px;}
  .sellertext{font-size: 18px;}
  .sellercard_wpr{overflow: scroll; -webkit-overflow-scrolling: touch; white-space: nowrap; scroll-behavior: smooth; padding-left: 16px; display: block; padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;}
  .sellercard{width: 61vw; margin-right: 20px; padding: 20px;height:250px;vertical-align: top;}
  .seller_comment{white-space: initial; word-break: break-word; font-size: 13px;line-height: 16px;}
  .sellerimg{width: 50px; height: 50px;}
  .rightquotationsign{width: 40px; height: 32px;}
  .seller_name{font-size: 13px;}
  .seller_store{font-size: 11px;}
  .carousel_nav span{height: 2px;}
  .carousel_nav span.act{background-color: #0076d7;}
  .carousel_nav{margin-top: 27px;}
  .sellercard_wpr::-webkit-scrollbar { display: none;}

  /*customer stories*/

  /*how to sell*/
  .howtosell_wpr{padding: 30px 0;}
  .howtosell_title{font-size: 12px;}
  .howtosell_text{font-size: 18px;}
  .howtosell_steps{flex-direction: column;}
  .howtosell_steps_content{width: 100%; display: inline-flex; flex-direction: row; text-align: left; vertical-align: top; justify-content: space-between; padding: 0 20px; align-items: flex-start;}
  .step_info{width: calc(100% - 80px); display: inline-block;}
  .howtosell_steps_title{text-align: left; padding-top: 0;font-size: 14px;}
  .howtosell_steps_para {font-size: 12px;line-height: 19px;padding-top: 5px;}
  .stepicon{width: 50px; height: 50px;}
  .howtosell_steps_content:after{background-image: none; left: 45px; top: 50px; width: 2px; height: 100%;border-left: 1px dashed #0076d7;}
  .howtosell_steps_content:nth-child(2):after{background-image: none; left: 45px; top: 50px; width: 2px; height: 100%;border-left: 1px dashed #0076d7;}  
  .howtosell_wpr.newdes {padding-top:20px; padding-bottom: 15px;}
  .howtosell_wpr.newdes .ultemplwrp {margin-top:0;}
.howtosell_wpr.newdes .ultemplwrp .ultempl .litempl {width: 100%; border-radius: 6px;margin-bottom: 11px;padding:14px 16px;height: auto;}
.howtosell_wpr.newdes .ultemplwrp .ultempl .litempl .imgbxwrp .imgbx {width: 55px;height: 55px;}
.howtosell_wpr.newdes .ultemplwrp .ultempl .litempl .contwrp .font25{font-size:15px;margin-bottom:4px;}
.howtosell_wpr.newdes .ultemplwrp .ultempl .litempl .contwrp .font20 {font-size:12px}
  /*how to sell*/




/*seller info*/
.seller_info_outer{padding: 30px 0;background: #fff9eb;}
.sellonjdmarketplce{margin-top: 40px}
.seller_info{background-color: transparent; padding: 0 20px; width: 100%;}
.sellerbig_img{position: relative; left: initial; width: 100%; height: 215px; top: 0; margin-top: 30px; background-size: cover; background-position: center;    right: unset; left: 0;    background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/seller_img_m.png);border-radius: 10px;}
.seller_info_title{text-align: center; font-size: 11px; text-transform: uppercase;}
.seller_info_boldtext{text-align: center; font-size: 18px; font-weight: 500; padding-top: 6px;padding-left: 30px;padding-right: 30px;}
.seller_info_list_title{font-size: 15px;}
.seller_info_list_text{font-size: 14px;line-height: 19px;}
.startselling_btn button{width: 100%;}
.sellonjdmarketplce .sellerbig_img {top:0;}
.sellonjdmarketplce .seller_info_boldtext {font-size:24px;padding: 0 20px;}
.sellonjdmarketplce .ultemplwrp {width: 100%;height: auto}
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl {margin-bottom: 20px;}
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl:last-child {margin-bottom: 0px;}
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl .imgbxwrp .imgbx {width:50px; height:50px;}
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl .contwrp .font22{font-size:16px;}
.seller_info_wpr.sellonjdmarketplce .ultemplwrp .ultempl .litempl .contwrp .font18{font-size:13px;}

/*seller info*/


/* customer experience*/
.seller_info_wpr.customerexp_wpr {margin-top:0;padding-top: 0;}
.seller_info_wpr.customerexp_wpr .seller_info_outer {background: #e8f6ff}
.seller_info_wpr.customerexp_wpr .seller_info{border-radius: 0; padding: 30px 20px 30px;width: 100%;}
.seller_info_wpr.customerexp_wpr .seller_info_boldtext{font-size: 24px; margin-top: 0;padding-top:0;margin-bottom: 20px;}
.seller_info_wpr.customerexp_wpr .seller_info_medtext {font-size:18px;    margin-top: 30px;}
.downloadselling_btn button{width: 100%;}
.seller_info_wpr.customerexp_wpr .sellerbig_img{right: 0;left: 0;width: 320px; height: 332px; top: 0; text-align: center; margin: 0 auto;}
.sellerbig_img .video_container{position: absolute; position: absolute; left: 12%; bottom: 25%; width: 77%;}
.sellerbig_img video{width: 100%;}
.web_img{display: none;}
.mob_img{display: block;}
.sellerbig_img img{max-width: 100%; height: auto;}
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl .imgbxwrp {vertical-align: top}
.seller_info_wpr.customerexp_wpr .ultemplwrp {width:100%;height:auto;}
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl {margin-bottom: 20px;}
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl .imgbxwrp .imgbx {width:50px; height:50px;}
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl .contwrp .font22{font-size:16px;}
.seller_info_wpr.customerexp_wpr .ultemplwrp .ultempl .litempl .contwrp .font18{font-size:13px;}
/* customer experience*/

/* shop Fee Structure */
.seller_info_wpr.shopFeeStruct .seller_info_outer {    padding-top: 30px;background: #fff7f9;}
.seller_info_wpr.shopFeeStruct .seller_info {padding-top:0;}
.seller_info_wpr.shopFeeStruct .seller_info_boldtext {padding: 0;font-size: 20px;}
.seller_info_wpr.shopFeeStruct .sellerbig_img {background-image: url('https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/banner-fee_m2x.png');top: 0;margin-bottom: 20px;}
.seller_info_wpr.shopFeeStruct .ultemplwrp {width: 100%;  height: auto; padding-bottom:10px;}
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl .contwrp .font18 {font-size: 16px;}
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl .contwrp .font15 {font-size: 13px;line-height: 19px;}
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl .imgbxwrp .imgbx {width: 50px; height: 50px;}
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl .contwrp .font22 {font-size:16px;margin-bottom: 4px;}
.seller_info_wpr.shopFeeStruct .ultemplwrp .ultempl .litempl .contwrp .font18 {font-size:13px;}
.seller_info_wpr.shopFeeStruct .seller_info .startselling_btn {margin-top:20px;}
/*common links*/
.commonlinks_outer{padding: 0;}
.commonlinks_outer li{width: 100%; border-width: 0 0 1px 0; padding: 18px 20px; font-size: 14px; text-align: left; justify-content: flex-start; height: auto; position: relative; margin-right: 0;}
.rightarrow{background-image: url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/hyperlocal/hl_black_right_arw.svg); width: 14px; height: 14px; display: inline-block; background-repeat: no-repeat; background-size: 100%;}
.commonlinks_outer li .rightarrow{position: absolute; right: 14px; top: 18px;}

/*common links*/


/*register now*/
.register_outer{flex-direction: column; justify-content: center; padding: 30px 20px;}
.register_title{text-align: center; font-size: 20px;}
.register_text{text-align: center; font-size: 16px; opacity: 0.71; padding-top: 12px;}
.register_btn{padding-left: 0; width: 100%; padding-top: 14px;}
.register_btn button{width: 100%; height: 40px;}
/*register now*/

/* commission fee */
.commission_fee_wpr{margin-top: 0;}
.commission_fee_outer{padding: 32px 0;}
.commission_fee_title{font-size: 11px; }
.commission_fee_text{font-size: 18px;padding-top: 7px;}
.commission_chart_outer{flex-direction: column; margin-top: 24px;}
.commission_chart_tabs{padding: 0 20px; width: 100%;}
.commission_chart_tabs ul{display: flex; width: 100%; justify-content: center; align-items: center; flex-direction: row;}
.commission_chart_tabs li{height: 35px; font-size: 13px; text-align: center; justify-content: center; align-items: center; margin-bottom: 0; color: #0076d7;}
.commission_chart_tabs li:first-child{border-radius: 18px 0 0 18px; border-style: solid; border-color: #0076d7; border-width: 1px 0 1px 1px;}
.commission_chart_tabs li:last-child{border-radius: 0 18px 18px 0; border-style: solid; border-color: #0076d7; border-width: 1px 1px 1px 0px;}
.commission_chart_tabs li.act:before{display: none;}
.commission_chart_tabs li.act{background-color: #0076d7; color: #fff;}
.commission_chart_content{margin-top: 16px; width: 100%; border: 0; border-radius: 0; padding: 0 0 20px;} 
.commission_chart_title{display: none;}
.comisn_according_outer{margin-top: 0; background-color: #f8f8f8; padding-top: 4px; border-top: 1px solid #eee;}
.comisn_according_title{border-width: 1px 0 0 0; border-radius: 0;border-color: #f6f6f6; font-size: 15px;}
.comisn_according_outer.act .comisn_according_title{border-width: 0; border-radius: 0; border-color: #f6f6f6;}
.comisn_according_title .downarrow{width: 18px; height: 18px;}
.comisn_according_outer.act .comisn_according_title .downarrow{transform: rotate(180deg); background-position: bottom;}
.categoryflex{display: none;}
.comisn_according_content{padding: 0; border: 0;}
.comisn_inner_according_outer{margin-top: 0;}
.comisn_inner_according_title{border-width: 1px 0 0 0; font-size: 14px; border-radius: 0; border-color: #f6f6f6;}
.comisn_inner_according_content{border:0; padding: 0;}
.comisn_inner_according_outer .comisn_inner_according_title{padding-left: 44px;}
.comisn_inner_according_outer.act .comisn_inner_according_title{border-radius: 0; border-color: #f6f6f6;}
.comisn_inner_according_title .downarrow{left: 20px; right: initial; width: 16px; height: 16px;}
.comisn_inner_according_title .downarrow{background-image: url(http://akam.cdn.jdmagicbox.com/images/icontent/newwap/realestate_desk/expand_plus_icon.svg); width: 16px; height: 16px; display: inline-block; background-size: 100%; background-repeat: no-repeat;}
.comisn_inner_according_outer.act .comisn_inner_according_title .downarrow{background-image: url(http://akam.cdn.jdmagicbox.com/images/icontent/newwap/realestate_desk/collapse_minus_icon.svg); margin-top: initial; transform: translateY(-50%);}
.inner_according_hdr{border-radius: 0; padding: 8px 20px 8px 40px;}
.inner_according_hdr .inner_according_left{display: none;}
.inner_according_hdr .inner_according_right{width: 100%;}
.inner_according_hdr .inner_according_right li{font-size: 11px; text-align: left;}
.inner_according_row{flex-direction: column; padding: 14px 20px 14px 40px; position: relative; border-bottom: 0;}
.inner_according_row:last-child:after{display: none;}
.inner_according_row:after{content: ''; position: absolute; left: 30px; bottom: 0; width: calc(100% - 60px); height: 1px; background-color: #f6f6f6;}
.inner_according_row .inner_according_row_left, .inner_according_row .inner_according_row_right{width: 100%;}
.inner_according_row_left{font-size: 12px; font-weight: 500;}
.inner_according_row_right{padding-top: 13px;}
.inner_according_row_right li{font-size: 12px; color: #777; text-align: left;}

.closing_chart_title{display: block; font-size: 15px; font-weight: bold; color: #111; padding: 16px 30px;}
.closefee_wpr.mt-25{margin-top: 0;}
.closefee_chart{border:0;}
.closefee_hdr{background-color: #f6f6f6; border-radius: 0;}
.closefee_th{font-size: 11px; padding: 8px 20px 8px 30px; text-align: left;}
.closefee_th:first-child, .closefee_td:first-child{border:0;}
.closefee_td{font-size: 12px; padding: 14px 20px 14px 30px; font-weight: bold; width: 50%; text-align: left;}
.closefee_row{position: relative; border-bottom: 0; display: flex; justify-content: space-between; flex-direction: row;}
.closefee_row:after{content: ''; position: absolute; left: 30px; bottom: 0; width: calc(100% - 60px); height: 1px; background-color: #f6f6f6;}
.closefee_td{display: table-cell; vertical-align: middle; padding: 16px 20px; font-size: 13px; color: #111; font-weight: 500; text-align: center; border-right: 1px solid #eee;}
.closefee_td:last-child{border-right: 0}

/* commission fee */

/*footer*/
.footer_section {padding:20px 0 ;}
.footerbox {flex-flow: column-reverse;}
.inner_footerbox {width:100%;margin:0;padding-top: 20px;}
.inner_footerbox:last-child {width:100%;border-bottom: #ccc solid 1px; padding-bottom: 20px;}
.footer_row1 {font-size:14px;margin-bottom:20px;}
.linklist .linklist_li a {font-size:13px;margin-bottom:10px;}
.inner_footerbox:first-child {text-align: center; border-bottom: 0; padding-bottom: 40px;}
.inner_footerbox:first-child .footer_row1 {font-size:13px;margin-bottom:6px;}
.inner_footerbox:first-child .jdbusiness_img {width: 173px;height: 26px;}
.inner_footerbox:first-child .footer_row2 {font-size:14px;}
.inner_footerbox:first-child .footer_row3 {margin-top:30px;justify-content: center;}
.inner_footerbox:first-child .footer_row3 .ctwrap {width:auto;margin-right: 20px;font-size: 0;}
.inner_footerbox:first-child .footer_row3 .ctwrap:last-child {margin-right:0;}
.inner_footerbox:nth-child(2) {padding-bottom: 20px;text-align:center;}
.inner_footerbox:nth-child(2) .footer_row1 {font-size: 13px;}
.inner_footerbox:nth-child(2) .copyright_wrapper {text-align:center;font-size: 13px;}
/*footer*/

}

@media only screen and (min-width: 960px) and (max-width: 1360px) {
  .containernew {padding:0 20px}
  .howtosell_steps_title {height: 71px;}
}


