.vm{vertical-align:middle;}
.spleftwrapper{display:inline-block;width:60%;font-size:0;vertical-align:top;}
.inner_spleftwrap{display:inline-block;width:60%;height:100px;vertical-align:top;}
.sellerw_package_spec{width:268px;height:225px;}
.inner_sprightwrap{display:inline-block;width:40%;}
.slrimgwpr{margin-top:40px;}
.slrimgwpr .slrimg{position:relative;width:326px;height:230px;}
.inner_sprightwrap input{border:0;outline:0;padding-bottom:11px;border-bottom:1px solid #f2f2f2;}
@media only screen and (max-width: 767px)
{
.spleftwrapper,.sprightwrapper,.inner_spleftwrap{width:100%;padding:0;display:block;}
.inner_spleftwrap{height:auto;}
.slrimgwpr .slrimg{width:100%;margin-bottom:20px;}
.sellerw_package_spec{width:85%;margin:0 auto;display:block;object-fit:contain;object-position:center;}
.inner_sprightwrap{display:block;width:100%;}
.sellingprice_wrapper{padding:15px;}
.slrimgwpr{margin-top:20px;}
}
