.autosuggest_autosgtulwpr{padding-top:0;position:relative;}
.autosuggest_srchpad{padding:0;}
.autosuggest_autosgtul{position:absolute;top:40px;left:0;border:1px solid #eee;border-bottom-left-radius:6px;border-bottom-right-radius:6px;background:#fff;width:100%;display:block;border-top:0;padding-top:10px;max-height:400px;overflow-y:auto;border:1px solid #eee;z-index: 1;}
.autosuggest_autosgtul::-webkit-scrollbar {
    -webkit-appearance: none;
    width:5px;
  }
  .autosuggest_autosgtul::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
.autosuggest_autosgtul li{padding:10px 20px;cursor:pointer;}
.autosuggest_autosgtcrosswp{display:none;}
.autosuggest_autosgtheading{padding:0 20px;margin-top: 5px;}
.autosuggest_autosgtheading + ul{margin-bottom: 15px;}
.color111{color: #111111;}
/* @media only screen and (max-width: 767px)
{
    .autosuggest_autosgtulwpr{width:100%;height:100%;background:#fff;position:fixed;top:0;left:0;z-index:5;}
    .autosuggest_autosgtul{width:calc(100% - 40px);margin:0 0 0 20px;top:70px;max-height:none;border:0;height:calc(100vh - 200px);}
    .autosuggest_autosgtul li{padding:10px 0;}
    .autosuggest_srchpad{padding:20px 20px 10px;}
    .autosuggest_autosgtcrosswp{display:block;}
    .autosuggest_autosgtcross{width:50px;height:50px;display:inline-block;vertical-align:middle;background:url(https://akam.cdn.jdmagicbox.com/images/icontent/newwap/newprot_w/closeicn.svg) no-repeat;background-position:center;cursor:pointer;}
    .autosuggest_autosgtheading{padding:0;}
  } */
